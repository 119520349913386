import { HStack, Link, StatusDot, Typography } from "@smartrent/ui";
import { useState } from "react";

import { useParams } from "react-router-dom";

import { useSocketChannelEvent } from "@/hooks/useSocketChannelEvent";
import { Controller } from "@/modules/controller/types";
import { controllerChannelTopic, Messages } from "@/lib/socket";
import { useChannel } from "@/hooks/useChannel";

import { useTableUtils } from "@/modules/base/table/utils";
import { Paths } from "@/lib/path";

interface ControllerStatusIndicatorProps {
  controller: Controller;
  disabled?: boolean;
}

export const ControllerNameAndStatusIndicator: React.FC<
  React.PropsWithChildren<ControllerStatusIndicatorProps>
> = ({ controller, disabled = false }) => {
  const { site_id } = useParams<{ site_id: string }>();
  const [isOnline, setIsOnline] = useState(controller.online);
  const [controllerChannel] = useChannel(controllerChannelTopic(controller));
  const { typographyProps, typographyPropsDisabled } = useTableUtils();

  useSocketChannelEvent(
    controllerChannel,
    Messages.controller_status,
    (response) => {
      setIsOnline(response.online || false);
    }
  );

  return (
    <HStack spacing={8} align="center">
      <StatusDot
        size="large"
        color={disabled ? "grayscale" : isOnline ? "success" : "error"}
        variation={disabled ? "outlined" : "solid"}
      />
      {disabled ? (
        <Typography {...typographyPropsDisabled}>{controller.name}</Typography>
      ) : (
        <Link
          {...typographyProps}
          color="primary"
          href={Paths.controllerViewPath(
            controller.hub?.site_id || site_id, // assumes we're always on a site page
            controller.id
          )}
        >
          {controller.name}
        </Link>
      )}
    </HStack>
  );
};
