import { get } from "lodash-es";
import axios from "axios";
import { Button } from "badmagic";

import { RefreshTokenProps } from "../../types";

export function RefreshTokenButton({
  activeProfile,
  setProfiles,
  profiles,
  setError,
  setSuccess,
  workspaceConfig,
  setActiveProfile,
}: RefreshTokenProps) {
  if (!activeProfile?.refreshToken) {
    return null;
  }

  return (
    <div className="my-4">
      <Button
        className="ml-2"
        onClick={async () => {
          setError("");
          setSuccess("Refreshing token, one moment");

          await axios({
            url: `${workspaceConfig.baseUrl}${workspaceConfig.refreshPath}`,
            method: "POST",
            data: {
              refresh_token: activeProfile.refreshToken,
            },
          })
            .then((response) => {
              const accessToken = get(
                response,
                `${workspaceConfig.pathToTokens}.access_token`
              );
              const refreshToken = get(
                response,
                `${workspaceConfig.pathToTokens}.refresh_token`
              );

              if (accessToken || refreshToken) {
                setSuccess("Profile refreshed");

                const newProfiles = [...profiles];
                const newProfile: Record<string, any> = {
                  ...activeProfile,
                };

                if (accessToken) {
                  newProfile.accessToken = accessToken;
                }
                if (refreshToken) {
                  newProfile.refreshToken = refreshToken;
                }

                const profileIndex = newProfiles.findIndex((profile) => {
                  return profile.name === newProfile.name;
                });
                if (profileIndex === -1) {
                  return null;
                }
                newProfiles.splice(profileIndex, 1, newProfile); // mutates array; replaces old profile with new

                setProfiles(newProfiles);
                setActiveProfile(newProfile); // we set this again so the latest access token/refresh token are on it
              } else {
                setError(
                  "Unable to fetch access_token and refresh_token from that endpoint, please contact Engineering."
                );
              }
              return null;
            })
            .catch((err) => {
              console.error(err);
              if (err?.response?.data) {
                setError(
                  `[${err?.response?.status}] - ${JSON.stringify(
                    err.response.data
                  )}`
                );
              } else {
                setError(err.message);
              }
            });
        }}
      >
        Refresh Token
      </Button>
    </div>
  );
}
