/**
 * In BadMagic, when a request is made, the request can have metadata associated with it.
 *
 * Through applyAxiosIntercerptors, we capture the `metadata.profile` (the profile used to make the request)
 * so that we can display it here
 */
export function HistoryMetadata({
  metadata,
}: {
  metadata: Record<string, any>;
}) {
  if (!metadata?.profile?.name) {
    return <></>;
  }

  return (
    <div className="flex justify-between">
      <div>Requested by: {metadata.profile.name}</div>
      <div>{new Date(metadata.insertedAt).toLocaleString()}</div>
    </div>
  );
}
