import {
  VStack,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerActions,
  Button,
} from "@smartrent/ui";

import {
  Form,
  FormField,
  FormNumberInputField,
  FormOnSubmit,
  FormSelectField,
  FormStaticTextField,
  FormSubmit,
  FormTextInputField,
} from "@smartrent/forms";

import * as yup from "yup";

import { useCallback } from "react";

import { AxiosError } from "axios";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { ControllerFormSelectField } from "@/modules/controller/components/ControllerFormSelectField";

import { SolicitApiErrorResponse } from "@/types/solicit";

import { isAxiosError } from "@/lib/api";

import {
  Door,
  DoorConfigurations,
  DoorModeOptions,
  ElevatorConfigurationOptions,
  ElevatorConfigurations,
  getDoorModeAssistiveText,
} from "../door/types";

import { Controller } from "../controller/types";

import { Floor } from "../floor/types";

import { ElevatorQueries } from "./queries";
import { Elevator } from "./types";
import { ElevatorAsyncDataFields } from "./components/ElevatorAsyncDataFields";

const createValidationSchema = yup.object().shape({
  name: yup.string().max(40).required().label("Name"),
  controller_id: yup.string().required("You must select a controller."),
  configuration: yup
    .string()
    .required("You must select a configuration.")
    .oneOf(ElevatorConfigurations),
  remote_id: yup.string().nullable().max(255).label("Remote Id"),
  strike_min: yup.number().nullable().min(1).label("Activated Output Time"),
});

const updateValidationSchema = yup.object().shape({
  name: yup.string().max(40).required().label("Name"),
  configuration: yup
    .string()
    .required("You must select a configuration.")
    .oneOf(ElevatorConfigurations),
  strike_min: yup
    .number()
    .nullable()
    .min(1)
    .max(14)
    .label("Activated Output Time"),
  strike_max: yup
    .number()
    .nullable()
    .min(1)
    .max(255)
    .label("Active Push Button Duration"),
});

interface ElevatorFormDrawerProps {
  initialValues?: Door;
  site_id: number;
}

interface ElevatorFormValues extends Partial<Elevator> {}

export const ElevatorFormDrawer: React.FC<
  React.PropsWithChildren<ElevatorFormDrawerProps>
> = ({ initialValues, site_id }) => {
  const isEdit = !!initialValues?.id;
  const { activeRoute, reset, pop } = useAppDrawer();

  const [create] = ElevatorQueries.useCreateMutation();

  // Note: 'floors' is omitted from payload; actions are handled via Floors table.
  const [update] = ElevatorQueries.useUpdateMutation();

  const handleSubmit = useCallback<FormOnSubmit<ElevatorFormValues>>(
    async (values, helpers) => {
      try {
        isEdit
          ? await update({ id: values.id as number, values: values as Door })
          : await create({ values });
        pop();
      } catch (error) {
        // try to display any api errors in the form
        if (isAxiosError(error)) {
          const response = (error as AxiosError)
            .response as SolicitApiErrorResponse<Door>;
          if (response?.data?.errors?.length) {
            response.data.errors.forEach((error) => {
              if (typeof error !== "string") {
                helpers.setFieldError(error.field, error.description);
              }
            });
          }
        } else {
          // close drawer, since there's no form errors to display
          pop();
        }
      }
    },
    [create, isEdit, pop, update]
  );

  return (
    <Form<ElevatorFormValues>
      initialValues={{
        // Set to undefined for yup validation error assignment.
        name: undefined,
        controller_id: undefined,
        strike_min: 1,
        configuration: DoorConfigurations.elevatorWithFloor,
        ...initialValues,
      }}
      validationSchema={
        isEdit ? updateValidationSchema : createValidationSchema
      }
      onSubmit={handleSubmit}
    >
      <Drawer
        testID={`elevator-form-drawer-${isEdit ? "edit" : "add"}`}
        open={!!activeRoute}
        onClose={reset}
        anchor="right"
      >
        <DrawerHeader
          title={initialValues?.name}
          subtitle={`${isEdit ? "Update" : "Add"} Elevator`}
        />
        <DrawerContent>
          <VStack spacing={8}>
            <FormTextInputField name="name" label="Name" required />
            <ControllerFormSelectField
              required
              closeOnChange={true}
              site_id={site_id}
              name="controller_id"
              label="Controller"
              getOptionValue={(option: Controller) => option.id.toString()}
              getOptionLabel={(option) => option.name?.toString() || ""}
              initialValue={initialValues?.controller_id?.toString()}
              disabled={isEdit}
            />
            <FormTextInputField
              name="remote_id"
              label="Remote Id"
              disabled={isEdit}
            />
            <FormSelectField
              required
              name="configuration"
              label="Configuration"
              options={ElevatorConfigurationOptions}
            />
            <FormField name="mode">
              {({ value }) => (
                <FormSelectField
                  name="mode"
                  label="Default Mode"
                  options={DoorModeOptions}
                  assistiveText={getDoorModeAssistiveText(value)}
                />
              )}
            </FormField>
            <FormField name="controller_id">
              {({ value, error }) => (
                <ElevatorAsyncDataFields
                  controller_id={value}
                  disabled={Boolean(error || !value)}
                />
              )}
            </FormField>

            <FormStaticTextField<Floor[]>
              name="floors"
              label="Number of Floors"
              transformValue={(value) =>
                value?.length ? value.length.toString() : ""
              }
              disabled
              onPress={() => null}
            />
            <FormField name="configuration">
              {({ value }) =>
                value == DoorConfigurations.elevatorWithFloor ? (
                  <FormNumberInputField
                    name="strike_min"
                    label="Activated Output Time"
                    assistiveText="Specifies the number of seconds the relay operates after an appropriate input is activated. The maximum duration is 14 seconds."
                  />
                ) : null
              }
            </FormField>
            <FormNumberInputField
              name="strike_max"
              label="Active Push Button Duration"
              assistiveText="Specifies the number of seconds the floor select push button enable relays operate. The maximum duration is 255 seconds."
            />
          </VStack>
        </DrawerContent>

        <DrawerActions>
          <Button variation="outlined" onPress={pop}>
            Cancel
          </Button>
          <FormSubmit label="Save" />
        </DrawerActions>
      </Drawer>
    </Form>
  );
};
