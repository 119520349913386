import { createAxiosMutation } from "@/hooks/react-query";
import { apiClient } from "@/lib/api";
import { getErrorMessage } from "@/lib/axios-helpers";
import { QueryKeys } from "@/types";

import { Paths } from "@/lib/path";

import { API_BASE_PATH, BaseQueryClient } from "../base/queries";
import { Notification } from "../notifications/types";

import { Site } from "./types";

const queryKey = QueryKeys.Sites;

class SiteQueryClient extends BaseQueryClient<Site> {
  constructor() {
    super({ queryKey });
  }

  public createRedirectPath = (created: Partial<Site>) => {
    return Paths.siteViewPath((created.id as number).toString());
  };

  useStartSyncMutation = createAxiosMutation(
    async ({ site_id, force }: { site_id: number; force: boolean }) => {
      const { data } = await apiClient.post(`/v1/sites/${site_id}/sync`, {
        force,
      });
      return data;
    },
    {
      successToast: () => ({
        status: "success",
        message: "Sync Started. This may take some time.",
      }),
      errorToast: (err: any) => ({
        status: "error",
        message: `Unable to start sync. ${getErrorMessage(err)}`,
      }),
    }
  );

  async getNotifications(siteId: number) {
    const { data } = await apiClient.get<Notification[]>(
      `${API_BASE_PATH}/${queryKey}/${siteId}/notifications`
    );

    return data;
  }
}
export const SiteQueries = new SiteQueryClient();
