import { useCallback, useEffect, useRef } from "react";
import { Channel } from "phoenix";

import { useSocketContext } from "@/context/SocketContext";

export const useChannel = (topic: string): [Channel, boolean] => {
  const { socket, channelMap, isConnected, onError, onTimeout } =
    useSocketContext();
  const channel = useRef(socket.channel(topic));
  const channelRef = useRef(Symbol());

  useEffect(() => {
    channel.current.onError(onError);
  }, [onError]);

  const join = useCallback(() => {
    return new Promise((resolve, reject) => {
      // channel is already been joined elsewhere
      if (channelMap.current.has(topic)) {
        const channelSet = channelMap.current.get(topic) as Set<symbol>;
        if (!channelSet.has(channelRef.current)) {
          channelMap.current.set(topic, channelSet.add(channelRef.current));
        }
        return resolve("Already joined");
      }

      channel.current
        .join()
        .receive("ok", (resp) => {
          channelMap.current.set(
            topic,
            new Set<symbol>().add(channelRef.current)
          );
          return resolve(resp);
        })
        .receive("error", (resp) => {
          return reject({ type: "error", e: resp });
        })
        .receive("timeout", () => {
          return reject({ type: "timeout" });
        });
    });
  }, [channelMap, topic]);

  const leave = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (!channelMap.current.get(topic)?.has(channelRef.current)) {
        return resolve("Already left");
      }

      channel.current
        .leave()
        .receive("ok", (resp) => {
          channelMap.current.get(topic)?.delete(channelRef.current);
          if (channelMap.current.get(topic)?.size === 0) {
            channelMap.current.delete(topic);
          }
          return resolve(resp);
        })
        .receive("error", (resp) => {
          return reject({ type: "error", e: resp });
        })
        .receive("timeout", () => {
          return reject({ type: "timeout" });
        });
    });
  }, [channelMap, topic]);

  useEffect(() => {
    if (!isConnected) return;

    join()
      .then(() => {
        //
      })
      .catch(({ type, e }) => {
        if (type === "error") {
          onError(e);
        } else if (type === "timeout") {
          onTimeout();
        }
      });

    return () => {
      leave()
        .then(() => {
          //
        })
        .catch(({ type, e }) => {
          if (type === "error") {
            onError(e);
          } else if (type === "timeout") {
            onTimeout();
          }
        });
    };
  }, [join, leave, isConnected, onError, onTimeout]);

  return [channel.current, isConnected];
};
