import { useContext, useEffect } from "react";
import {
  FlatListItemSeparator,
  ListItemLabelValue,
  ListItemTouchableContainer,
  Panel,
  PanelHeader,
  PanelHeaderTitle,
  useTheme,
} from "@smartrent/ui";
import { ChevronRight } from "@smartrent/icons";

import { PageCtx } from "@/context/PageContext";
import { usePermissions } from "@/context/PolicyContext";

import { Paths } from "@/lib/path";

import { Site, SystemTypes } from "@/modules/site/types";
import { SiteFirmwareQueries } from "@/modules/site-firmware/queries";

interface SiteSettingsPageProps {
  site: Site;
}

export const SiteSettingsPage = ({ site }: SiteSettingsPageProps) => {
  const { addBreadcrumb, breadCrumbs } = useContext(PageCtx);
  const { colors } = useTheme();

  useEffect(() => {
    addBreadcrumb({
      label: `Site Settings`,
      to: Paths.siteSettingsPath(site.id),
    });
  }, [breadCrumbs, addBreadcrumb, site.id]);

  return (
    <Panel>
      <PanelHeader>
        <PanelHeaderTitle title={`Site Settings`} />
      </PanelHeader>
      <ListItemTouchableContainer
        to={Paths.siteSettingsGeneralPath(site.id)}
        rightDetail={<ChevronRight color={colors.textPrimary} />}
      >
        <ListItemLabelValue
          label="General"
          value="Manage the general site settings"
        />
      </ListItemTouchableContainer>
      <FirmwarePanel site={site} />
    </Panel>
  );
};

const FirmwarePanel = ({ site }: SiteSettingsPageProps) => {
  const { colors } = useTheme();
  const { canView } = usePermissions({
    queryKey: SiteFirmwareQueries.queryKey,
  });

  if (site.system_type != SystemTypes.Alloy || !canView) return null;

  return (
    <>
      <FlatListItemSeparator />
      <ListItemTouchableContainer
        to={Paths.siteSettingsFirmwarePath(site.id)}
        rightDetail={<ChevronRight color={colors.textPrimary} />}
      >
        <ListItemLabelValue
          label="Firmware"
          value="Manage the firmware version for each controller"
        />
      </ListItemTouchableContainer>
    </>
  );
};
