import * as Sentry from "@sentry/react";

import { isAxiosError } from "./api";

if (process.env.SENTRY_DSN) {
  const isApiResponseError = (err: any): boolean => {
    if (!isAxiosError(err)) {
      return false;
    }

    return typeof err.response?.status === "number";
  };

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    beforeSend: (event, hint) => {
      // filter out axios errors that have a response status code
      if (isApiResponseError(hint?.originalException)) {
        return null;
      }

      return event;
    },
  });
}

export default Sentry;
