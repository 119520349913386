import {
  createContext,
  MutableRefObject,
  ReactNode,
  // useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Socket as PhxSocket } from "phoenix";

// import { ScrollView, StyleSheet, View } from "react-native";
// import { HStack, Typography, useTheme } from "@smartrent/ui";

import { AuthContext } from "./Auth";

type SocketChannelMap = Map<string, Set<symbol>>;

type ErrorCallback = (e: any) => void;
type TimeoutCallback = () => void;

const retryDelay = (attemptNumber: number) =>
  attemptNumber * 1000 * attemptNumber;

const SocketContext = createContext<{
  channelMap: MutableRefObject<SocketChannelMap>;
  socket: AASocket;
  isConnected: boolean;
  onError: ErrorCallback;
  onTimeout: TimeoutCallback;
}>(undefined!);

class AASocket extends PhxSocket {
  setParams(params: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- I swear this works.
    // @ts-ignore This does exist, it's just deprecated.
    // We will need to watch this if we upgrade to a newer version of the phoenix socket.
    this.params = params;
  }
}

const useSocket = ({
  onError,
  socketUrl,
}: {
  onError: ErrorCallback;
  socketUrl: string;
}): {
  socket: AASocket;
  isConnected: boolean;
} => {
  const socket = useRef(new AASocket(socketUrl, {}));
  const [isConnected, setIsConnected] = useState(false);
  const { accessToken } = useContext(AuthContext);

  const onOpen = () => {
    setIsConnected(true);
  };

  const onClose = () => {
    setIsConnected(false);
  };

  const openRef = useRef<string>("");
  const closeRef = useRef<string>("");
  const errorRef = useRef<string>("");

  // 1. tearDown for socket, when hook unmounts
  useEffect(() => {
    const socketCurrentCopy = socket.current;
    const openRefCopy = openRef.current;
    const closeRefCopy = closeRef.current;
    const errorRefCopy = errorRef.current;
    return () => {
      socketCurrentCopy.disconnect(() => {
        socketCurrentCopy.off([openRefCopy, closeRefCopy, errorRefCopy]);
      });
    };
  }, []);

  // 2. check for changes in token, and connect/disconnect socket accordingly
  useEffect(() => {
    // 1. do nothing, no token
    if (!accessToken && !isConnected) {
      return;
    }

    // 2. pre-existing socket exists, no token, disconnect immediately
    if (!accessToken && isConnected) {
      socket.current.disconnect(() => {
        socket.current.off([
          openRef.current,
          closeRef.current,
          errorRef.current,
        ]);
      });
      return;
    }

    // 3. new token, pre-existing socket exists, update guardian_token on existing socket
    if (accessToken && isConnected) {
      socket.current.setParams({ guardian_token: accessToken });
      return;
    }

    // 4. no socket exists, new token found, create new socket
    socket.current = new AASocket(socketUrl, {
      params: {
        // The token is set here for the first setup of the socket.
        // The token will automatically update the variable, but it will last until it attempts to reconnect.
        guardian_token: accessToken,
      },
      reconnectAfterMs: retryDelay,
    });

    // this overwrites the old refs we defined above
    openRef.current = socket.current.onOpen(onOpen);
    closeRef.current = socket.current.onClose(onClose);
    errorRef.current = socket.current.onError(onError);

    socket.current.connect(); // isConnected should be true after this
  }, [accessToken, isConnected, onError, socketUrl]);

  return { socket: socket.current, isConnected };
};

export const SocketProvider = ({
  children,
  onError,
  onTimeout,
}: {
  children: ReactNode;
  onError: ErrorCallback;
  onTimeout: TimeoutCallback;
}) => {
  const socketUrl = process.env.SOCKET_BASE_URL as string;
  const { socket, isConnected } = useSocket({ onError, socketUrl });
  const channelMap = useRef<SocketChannelMap>(new Map());

  useEffect(() => {
    if (!isConnected) {
      channelMap.current = new Map();
    }
  }, [isConnected]);

  return (
    <SocketContext.Provider
      value={{ channelMap, socket, isConnected, onError, onTimeout }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => useContext(SocketContext);
