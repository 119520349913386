import {
  VStack,
  FormikTextInputField,
  FormikDatepickerField,
} from "@smartrent/ui";
import { Form } from "formik";
import * as yup from "yup";

import { fetchAndSetSiteNotifications } from "@/components/alloy-access/NotificationBanner";

import { BaseForm } from "../base/Form";

import { HolidaySchedule } from "./types";
import { HolidayScheduleQueries } from "./queries";

interface HolidayScheduleProps {
  initialValues?: Partial<HolidaySchedule>;
  params: { site_id: number; holiday_schedule_group_id: number };
}

const validationSchema = yup.object().shape({
  name: yup.string().required("Required").max(40),
  start_at: yup.date().required("Required"),
  end_at: yup.date().required("Required"),
});

const assistiveText =
  "Exceptions allow you to create groups of one or more days (ex. Federal Holidays) that will override regular unlock schedules";

export const HolidayForm = ({
  initialValues,
  params,
}: HolidayScheduleProps) => {
  return (
    <BaseForm<HolidaySchedule>
      afterSubmit={fetchAndSetSiteNotifications}
      initialValues={{
        site_id: params.site_id,
        holiday_schedule_group_id: params.holiday_schedule_group_id,
        ...initialValues,
      }}
      validationSchema={validationSchema}
      QueryClient={HolidayScheduleQueries}
      params={params}
    >
      {() => (
        <Form>
          <VStack spacing={16}>
            <FormikTextInputField
              name="name"
              label="Name"
              assistiveText={assistiveText}
              required
            />
            <FormikDatepickerField name="start_at" label="Start At" required />
            <FormikDatepickerField name="end_at" label="End At" required />
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};
