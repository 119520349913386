import { fetchAndSetSiteNotifications } from "@/components/alloy-access/NotificationBanner";
import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { HolidayScheduleGroup } from "./types";

const queryKey = QueryKeys.HolidayScheduleGroups;

class HolidayScheduleGroupClient extends BaseQueryClient<HolidayScheduleGroup> {
  constructor() {
    const afterModify = async () => {
      return fetchAndSetSiteNotifications();
    };
    super({ afterModify, queryKey });
  }
}

export const HolidayScheduleGroupQueries = new HolidayScheduleGroupClient();
