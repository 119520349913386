import React, { useState, useMemo, useEffect, useCallback } from "react";

import {
  Select,
  Label,
  Success,
  Error,
  useGlobalContext,
  WorkspaceConfig,
} from "badmagic";

import {
  AuthProfiles,
  BearerProfile,
  HmacProfile,
  WorkspaceAuthStrategies,
} from "../types";

import { CreateHmacProfile } from "./common/CreateHmacProfile";
import { CreateBearerProfile } from "./common/CreateBearerProfile";
import { RefreshTokenButton } from "./common/RefreshTokenButton";
import { CreatePassBearerProfile } from "./common/CreatePassBearerProfile";

export function AuthForm({
  workspaceConfig,
}: {
  workspaceConfig: WorkspaceConfig;
}) {
  const { darkMode } = useGlobalContext();
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [profiles, setProfilesInState] = useState<AuthProfiles[]>([]);
  const [activeProfile, setActiveProfileInState] =
    useState<AuthProfiles | null>(null);

  // Fetches Profiles from local storage on load, also fetches Active Profile
  useEffect(() => {
    try {
      const authProfiles = localStorage.getItem("auth-profiles");

      const parsedProfiles = authProfiles ? JSON.parse(authProfiles) : [];
      if (window.websocketAccessToken) {
        const newProfile = {
          name: "Current Session",
          accessToken: window.websocketAccessToken,
        };

        // Checks if the profile already existed in `auth-profiles`
        // If the profile already exists, it replaces the pre-existing profile with the new profile
        // If it didn't exist, it appends to the array
        const profileIndex = parsedProfiles.find((profile: AuthProfiles) => {
          return profile.name === newProfile.name;
        });
        if (profileIndex) {
          parsedProfiles.splice(profileIndex, 1, newProfile); // mutates array; replaces old profile with new
        } else {
          parsedProfiles.unshift(newProfile); // prepends
        }
      }
      setProfilesInState(parsedProfiles);

      const activeProfile = localStorage.getItem("active-profile");

      if (activeProfile) {
        setActiveProfileInState(JSON.parse(activeProfile));
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const filteredProfiles = useMemo(() => {
    let temp: AuthProfiles[] = [];
    if (
      workspaceConfig?.authStrategies.includes(WorkspaceAuthStrategies.Hmac)
    ) {
      temp = temp.concat(
        profiles.filter((profile: HmacProfile) => !!profile.key)
      );
    }
    if (
      workspaceConfig?.authStrategies.includes(WorkspaceAuthStrategies.Bearer)
    ) {
      temp = temp.concat(
        profiles.filter((profile: BearerProfile) => !!profile.accessToken)
      );
    }
    return temp;
  }, [workspaceConfig, profiles]);

  const setProfiles = useCallback<(profiles: Record<string, any>[]) => void>(
    (profiles) => {
      setProfilesInState(profiles);
      localStorage.setItem("auth-profiles", JSON.stringify(profiles));
    },
    [setProfilesInState]
  );

  const setActiveProfile = useCallback<(profile: AuthProfiles | null) => void>(
    (activeProfile) => {
      setActiveProfileInState(activeProfile);
      localStorage.setItem("active-profile", JSON.stringify(activeProfile));
    },
    [setActiveProfileInState]
  );

  const styles = useMemo(() => {
    return {
      authFormBackground: darkMode
        ? "bg-gray-900 border-gray-700"
        : "bg-gray-200 border-gray-400",
    };
  }, [darkMode]);

  return (
    <div
      className={`border ${styles.authFormBackground} rounded pt-4 px-4 mb-4`}
    >
      {profiles?.length ? (
        <>
          <Label>Select an Auth Profile to use with this API Request</Label>

          <Select
            value={activeProfile?.name}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (e.currentTarget.value) {
                const profile = profiles.find(
                  (profile) => profile.name === e.currentTarget.value
                );
                if (profile) {
                  localStorage.setItem(
                    "active-profile",
                    JSON.stringify(profile)
                  );
                  setActiveProfile(profile);
                }
              } else {
                localStorage.removeItem("active-profile");
                setActiveProfile(null);
              }
            }}
          >
            <option value="">Select Profile</option>
            {filteredProfiles.map((profile) => (
              <option key={profile?.name} value={profile.name}>
                {profile.name}
              </option>
            ))}
          </Select>
        </>
      ) : null}
      {/* <div>TODO show Re-Login button</div>
        <div>TODO show specify password again for login if Re-Login fails</div> */}

      <div className="flex flex-wrap">
        {workspaceConfig.authStrategies.includes(
          WorkspaceAuthStrategies.Bearer
        ) ? (
          <CreateBearerProfile
            workspaceConfig={workspaceConfig}
            setError={setError}
            setSuccess={setSuccess}
            setProfiles={setProfiles}
            profiles={profiles}
            setActiveProfile={setActiveProfile}
          />
        ) : null}
        {workspaceConfig.authStrategies.includes(
          WorkspaceAuthStrategies.PassBearer
        ) ? (
          <CreatePassBearerProfile
            workspaceConfig={workspaceConfig}
            setError={setError}
            setSuccess={setSuccess}
            setProfiles={setProfiles}
            profiles={profiles}
            setActiveProfile={setActiveProfile}
          />
        ) : null}
        {workspaceConfig.authStrategies.includes(
          WorkspaceAuthStrategies.Hmac
        ) ? (
          <CreateHmacProfile
            workspaceConfig={workspaceConfig}
            setError={setError}
            setSuccess={setSuccess}
            setProfiles={setProfiles}
            profiles={profiles}
            setActiveProfile={setActiveProfile}
          />
        ) : null}
        {workspaceConfig.authStrategies.includes(
          WorkspaceAuthStrategies.Bearer
        ) ? (
          <RefreshTokenButton
            activeProfile={activeProfile}
            workspaceConfig={workspaceConfig}
            setError={setError}
            setSuccess={setSuccess}
            setProfiles={setProfiles}
            profiles={profiles}
            setActiveProfile={setActiveProfile}
          />
        ) : null}
      </div>
      {success || error ? (
        <div className="m-2">
          <Success>{success}</Success>
          <Error>{error}</Error>
        </div>
      ) : null}
    </div>
  );
}
