import {
  FilterProps,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import { StyleSheet, View } from "react-native";

import { useCallback } from "react";

import { useParams } from "react-router-dom";

import { humanize } from "@/lib/helpers";

import { useDialog } from "@/context/dialog";
import { usePermissions } from "@/context/PolicyContext";

import { fetchAndSetSiteNotifications } from "@/components/alloy-access/NotificationBanner";
import { useAppDrawer } from "@/components/layout/AppDrawer";

import { TableFilters } from "@/modules/base/table/types";

import { createActionsColumn, CreateButton } from "../base/table/utils";

import { Site } from "../site/types";

import { HolidaySchedule } from "./types";
import { HolidayScheduleQueries } from "./queries";

interface HolidayTableProps {
  filters: Partial<TableFilters & HolidaySchedule>;
  holiday_group_name: string;
  site: Site;
}

export const HolidaySchedulesTable = ({
  filters: filterParams,
  site,
  holiday_group_name,
}: HolidayTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const { canCreate, canDelete, canUpdate } = usePermissions(
    HolidayScheduleQueries
  );

  const onCreate = useCallback(
    () => drawer.push(HolidayScheduleQueries.queryKey, { params }),
    [drawer, params]
  );

  const onUpdate = useCallback(
    (row: HolidaySchedule) =>
      drawer.push(HolidayScheduleQueries.queryKey, {
        initialValues: { ...row },
        params,
      }),
    [drawer, params]
  );

  const { confirm } = useDialog();
  const [deleteQuery] = HolidayScheduleQueries.useDeleteMutation();

  const onDelete = useCallback(
    async (row: HolidaySchedule) => {
      const confirmed = await confirm({
        title: "Delete",
        description: "Are you sure you want to delete this Entry?",
        confirmText: "Delete",
        confirmType: "destructive",
        cancelText: "Cancel",
      });

      if (confirmed) {
        await deleteQuery({ id: row.id });
        await fetchAndSetSiteNotifications();
      }
    },
    [confirm, deleteQuery]
  );

  const tableProps = useTableQuery<
    any,
    HolidaySchedule,
    ListQueryResponse<HolidaySchedule>
  >({
    columns: [
      {
        name: "name",
        header: "Name",
        render: ({ row }) => {
          return <Typography>{row.name} </Typography>;
        },
        sortable: true,
        filter: (props: FilterProps) => {
          return <FormikTextInputField {...props} />;
        },
      },
      {
        //set the name for start at for sorting
        name: "start_at",
        header: "Date",
        render: ({ row }) => <DateEntry row={row} />,
        sortable: true,
      },
      createActionsColumn<HolidaySchedule>({
        canUpdate,
        onUpdate,
        canDelete,
        onDelete,
      }),
    ],
    fetch: HolidayScheduleQueries.fetch,
    getQueryKey: ({ filters, page, pageSize, sortColumn, sortDirection }) => [
      HolidayScheduleQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...filterParams,
      },
    ],
    defaultPageSize: 10,
  });

  return (
    <View style={styles.tableContainer}>
      <Table<HolidaySchedule>
        title={
          holiday_group_name === undefined
            ? humanize(HolidayScheduleQueries.queryKey)
            : holiday_group_name
        }
        noRecordsText="No Exceptions Found"
        style={styles.table}
        action={
          <CreateButton
            canCreate={canCreate}
            onCreate={onCreate}
            text="New Exception"
          />
        }
        {...tableProps}
      />
    </View>
  );
};

const DateEntry = ({ row }: { row: HolidaySchedule }) => {
  const getDay = (date: Date) =>
    new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(date);

  const start_day = getDay(new Date(row.start_at));
  const end_day = getDay(new Date(row.end_at));

  const day_string =
    start_day === end_day ? start_day : `${start_day} - ${end_day}`;
  const date_string =
    row.start_at === row.end_at
      ? row.start_at
      : `${row.start_at} - ${row.end_at}`;

  return (
    <View>
      <Typography>{day_string}</Typography>
      <Typography>{date_string}</Typography>
    </View>
  );
};

const styles = StyleSheet.create({
  tableContainer: { zIndex: -500 },
  table: { zIndex: -500 },
});
