import { useCallback } from "react";

import { StyleSheet } from "react-native";

import {
  TabProvider,
  Panel,
  PanelHeader,
  TabsNavigation,
  TabContent,
} from "@smartrent/ui";

import { NonRouterTabItem } from "@smartrent/ui/dist/components/TabsNavigation/types";

import { useQueryClient } from "@tanstack/react-query";

import {
  Controller,
  ControllerProtocols,
  ControllerModels,
} from "@/modules/controller/types";

import { Site } from "@/modules/site/types";

import { DelayLoad } from "@/components/DelayLoad";

import { QueryKeys } from "@/types";

import { ControllerAndHubRepliesPanel } from "../shared/ControllerAndHubRepliesPanel";

import { ControllerPanelsDisplay } from "./panels/ControllerPanelsDisplay";
import { ControllerReadersDisplay } from "./panels/ControllerReadersDisplay";
import { ControllerOutputsDisplay } from "./panels/ControllerOutputsDisplay";
import { ControllerInputsDisplay } from "./panels/ControllerInputsDisplay";
import { ControllerCallLogsDisplay } from "./panels/ControllerCallLogsDisplay";
import { ControllerSipAccountsDisplay } from "./panels/ControllerSipAccountsDisplay";
import { ControllerEncodersDisplay } from "./panels/ControllerEncodersDisplay";
import { ControllerOsdpDisplay } from "./panels/ControllerOsdpDisplay";
import { ControllerFirmwareHistoryDisplay } from "./panels/ControllerFirmwareHistoryDisplay";

const showPanels = (protocol: ControllerProtocols | undefined) => {
  return (
    protocol &&
    [ControllerProtocols.mpl, ControllerProtocols.asp].includes(protocol)
  );
};

const showReaders = (protocol: ControllerProtocols | undefined) => {
  return (
    protocol &&
    [
      ControllerProtocols.mpl,
      ControllerProtocols.smrtp,
      ControllerProtocols.asp,
    ].includes(protocol)
  );
};

const showInputs = (protocol: ControllerProtocols | undefined) => {
  return (
    protocol &&
    [
      ControllerProtocols.mpl,
      ControllerProtocols.smrtp,
      ControllerProtocols.asp,
    ].includes(protocol)
  );
};

const showOutputs = (protocol: ControllerProtocols | undefined) => {
  return (
    protocol &&
    [
      ControllerProtocols.mpl,
      ControllerProtocols.smrtp,
      ControllerProtocols.asp,
    ].includes(protocol)
  );
};

const showCallLogs = (protocol?: ControllerProtocols) => false;

const showSip = (protocol: ControllerProtocols | undefined) => {
  return protocol && [ControllerProtocols.akvx].includes(protocol);
};

const showEncoders = (protocol: ControllerProtocols | undefined) => {
  return (
    protocol &&
    [ControllerProtocols.ship, ControllerProtocols.schlage].includes(protocol)
  );
};

const showOsdp = (model: ControllerModels | undefined) =>
  model == ControllerModels.WARBLER;

const showFirmwareHistory = (protocol?: ControllerProtocols) =>
  protocol == ControllerProtocols.mpl;

interface ControllerActionPanelProps {
  controller: Controller;
  site: Site;
}

export const ControllerActionPanel = ({
  controller,
  site,
}: ControllerActionPanelProps) => {
  const queryClient = useQueryClient();
  const tabs = useCallback(() => {
    const currentTabs: Array<NonRouterTabItem> = [];

    showPanels(controller.protocol) &&
      currentTabs.push({
        id: "panels",
        label: "Panels",
        onPress: () => queryClient.invalidateQueries([QueryKeys.Panels]),
      });

    showReaders(controller.protocol) &&
      currentTabs.push({
        id: "readers",
        label: "Readers",
      });

    showInputs(controller.protocol) &&
      currentTabs.push({
        id: "inputs",
        label: "Inputs",
      });

    showOutputs(controller.protocol) &&
      currentTabs.push({
        id: "outputs",
        label: "Outputs",
      });

    showCallLogs(controller.protocol) &&
      currentTabs.push({
        id: "call-logs",
        label: "Call Logs",
      });

    showSip(controller.protocol) &&
      currentTabs.push({
        id: "sip",
        label: "SIP",
      });

    showEncoders(controller.protocol) &&
      currentTabs.push({
        id: "encoders",
        label: "Encoders",
      });

    showOsdp(controller.model) &&
      currentTabs.push({
        id: "osdp",
        label: "OSDP",
      });

    showFirmwareHistory(controller.protocol) &&
      currentTabs.push({
        id: "firmware-history",
        label: "Firmware",
      });

    currentTabs.push({
      id: "watch",
      label: "Watch",
    });

    return currentTabs;
  }, [controller.protocol, controller.model, queryClient]);

  const body = useCallback(() => {
    return (
      <>
        {showPanels(controller.protocol) ? (
          <TabContent id="panels">
            <ControllerPanelsDisplay site={site} controller={controller} />
          </TabContent>
        ) : null}

        {showReaders(controller.protocol) ? (
          <TabContent id="readers">
            <ControllerReadersDisplay controller={controller} />
          </TabContent>
        ) : null}

        {showInputs(controller.protocol) ? (
          <TabContent id="inputs">
            <ControllerInputsDisplay controller={controller} />
          </TabContent>
        ) : null}

        {showOutputs(controller.protocol) ? (
          <TabContent id="outputs">
            <ControllerOutputsDisplay controller={controller} />
          </TabContent>
        ) : null}

        {showCallLogs(controller.protocol) ? (
          <TabContent id="call-logs">
            <ControllerCallLogsDisplay controller={controller} />
          </TabContent>
        ) : null}

        {showSip(controller.protocol) ? (
          <TabContent id="sip">
            <ControllerSipAccountsDisplay controller={controller} />
          </TabContent>
        ) : null}

        {showEncoders(controller.protocol) ? (
          <TabContent id="encoders">
            <ControllerEncodersDisplay controller={controller} site={site} />
          </TabContent>
        ) : null}

        {showOsdp(controller.model) ? (
          <TabContent id="osdp">
            <ControllerOsdpDisplay controller={controller} />
          </TabContent>
        ) : null}

        {showFirmwareHistory(controller.protocol) ? (
          <TabContent id="firmware-history">
            <ControllerFirmwareHistoryDisplay
              controller={controller}
              site={site}
            />
          </TabContent>
        ) : null}

        <TabContent id="watch">
          {/* There is currently a bug that makes it so we can't load two sockets with the same topic */}
          {/* This is a hacky solution that should be removed as soon as we resolve the socket issue */}
          <DelayLoad msDelay={1000}>
            <ControllerAndHubRepliesPanel controller={controller} />
          </DelayLoad>
        </TabContent>
      </>
    );
  }, [controller, site]);

  return (
    <TabProvider>
      <Panel>
        <PanelHeader style={styles.header}>
          <TabsNavigation
            align="flex-start"
            initialActiveTabId={tabs()[0].id}
            items={tabs()}
          />
        </PanelHeader>
        {body()}
      </Panel>
    </TabProvider>
  );
};

const styles = StyleSheet.create({
  header: {
    padding: 0,
    paddingBottom: 8,
  },
});
