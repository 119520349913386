import React, { useContext, useEffect } from "react";
import { omit } from "lodash-es";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { reactQueryConfig } from "@smartrent/hooks";
import {
  ActionSheetProvider,
  PlatformProvider,
  ThemeProvider,
  ToastProvider,
  UploadcareProvider,
  themes,
} from "@smartrent/ui";

import { DevRouter } from "@/pages/dev";
import { SuperAdminRouter } from "@/pages/superadmin";
import { AdminRouter } from "@/pages/admin";
import { SiteRouter } from "@/pages/site";
import { SessionRouter } from "@/pages/session";
import { LinkAccountToUmbrellaUser } from "@/pages/LinkAccountToUmbrellaUser";

import { AppContainer } from "./components/layout/AppContainer";
import { AuthProvider, AuthContext, AuthSwitch } from "./context/Auth";
import { User, UserRole } from "./modules/user/types";
import { DialogProvider } from "./context/dialog";
import { AppDrawer } from "./components/layout/AppDrawer";
import { Page } from "./components/layout/Page";
import { SocketProvider } from "./context/SocketContext";
import { PageContextProvider } from "./context/PageContext";
import { NavigationLinksProvider } from "./context/NavigationLinksContext";
import { SentryErrorBoundary } from "./components/Sentry";
import { PermissionsProviderContainer as PermissionsProvider } from "./context/PolicyContext";

import { qsFromLocation, updateQS } from "./lib/helpers";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...reactQueryConfig.defaultOptions?.queries,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  },
});

const AuthenticatedRouter: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user } = useContext(AuthContext);

  // Used to maintain query params across redirects, required for CS login because we sometimes get
  // redirected to `/admin` without QS params before the Unauthenticated Router kicks in.
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // Clear the CS id_token from the QS after login or if it went unused
    if (location.search && location.search.includes("id_token") && user) {
      const queryString = qsFromLocation(location);
      updateQS({
        history,
        update: omit(queryString, ["id_token"]),
        location,
        omitEmpty: true,
      });
    }
  }, [history, location, user]);

  return (
    <PageContextProvider>
      <NavigationLinksProvider>
        <Page>
          <Switch>
            <Route path={`/superadmin`}>
              {user?.role === UserRole.SuperAdmin ? (
                <SuperAdminRouter user={user as User} />
              ) : (
                <Redirect to={`/${location.search}`} />
              )}
            </Route>
            <Route path={`/admin`}>
              {user?.role !== UserRole.SuperAdmin ? (
                <AdminRouter user={user as User} />
              ) : (
                <Redirect to={`/${location.search}`} />
              )}
            </Route>
            <Route path={`/sites/:site_id`}>
              {user?.role !== UserRole.SuperAdmin ? (
                <SiteRouter user={user as User} />
              ) : (
                <Redirect to={`/${location.search}`} />
              )}
            </Route>
            <Route path={`/dev`}>
              {process.env.EXPOSE_DEVELOPMENT_ROUTES ? (
                <DevRouter user={user as User} />
              ) : (
                <Redirect to={`/${location.search}`} />
              )}
            </Route>
            <Route
              path="/link-account/:id_token"
              component={LinkAccountToUmbrellaUser}
            />
            <Route exact path={`/`}>
              {user?.role === UserRole.SuperAdmin ? (
                <Redirect to={`/superadmin${location.search}`} />
              ) : (
                <Redirect to={`/admin${location.search}`} />
              )}
            </Route>
          </Switch>
        </Page>
      </NavigationLinksProvider>
    </PageContextProvider>
  );
};

const onSocketError = (e: any) => {
  console.warn("Error with socket", e);
};

const onSocketTimeout = () => {
  console.warn("Socket timed out");
};

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={themes.alloy} initialMode="light">
        <PlatformProvider>
          <UploadcareProvider publicKey="39bbc872346e757f7155">
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <ActionSheetProvider>
                <AuthProvider>
                  <PermissionsProvider>
                    <SocketProvider
                      onError={onSocketError}
                      onTimeout={onSocketTimeout}
                    >
                      <ToastProvider>
                        <AppContainer>
                          <DialogProvider>
                            <AppDrawer>
                              <SentryErrorBoundary>
                                <AuthSwitch
                                  authenticated={AuthenticatedRouter}
                                  unauthenticated={SessionRouter}
                                />
                              </SentryErrorBoundary>
                            </AppDrawer>
                          </DialogProvider>
                        </AppContainer>
                      </ToastProvider>
                      {/* <SocketDebugger /> */}
                    </SocketProvider>
                  </PermissionsProvider>
                </AuthProvider>
              </ActionSheetProvider>
            </QueryClientProvider>
          </UploadcareProvider>
        </PlatformProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
