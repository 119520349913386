import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useCallback, useContext, useEffect } from "react";

import { Rocket } from "@smartrent/icons";

import { LinkData } from "@/components/alloy-access/NavDrawer";
import { User } from "@/modules/user/types";

import squares from "@/assets/images/squares.svg";
import { NavigationLinksCtx } from "@/context/NavigationLinksContext";

import { BadMagicClient } from "./badmagic/BadMagic";

interface DevRouterProps {
  user: User;
}

export const DevRouter: React.FC<
  React.PropsWithChildren<DevRouterProps>
> = () => {
  const { path } = useRouteMatch();
  const { setNavigationLinks } = useContext(NavigationLinksCtx);

  const NavLinks = useCallback(() => {
    return [
      {
        label: "Development",
        icon: squares,
        items: [
          { url: `/dev/api`, label: "Bad Magic", icon: Rocket },
        ] as LinkData[],
      },
    ];
  }, []);

  useEffect(() => {
    setNavigationLinks(NavLinks());
  }, [setNavigationLinks, NavLinks]);

  return (
    <Switch>
      <Route exact path={`${path}/api`}>
        <BadMagicClient />
      </Route>
    </Switch>
  );
};
