import { Form, FormSubmit, FormOnSubmit, FormProps } from "@smartrent/forms";
import {
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerActions,
  Button,
} from "@smartrent/ui";

import { useCallback } from "react";

import { useHistory } from "react-router-dom";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { getFormFieldsErrors } from "@/lib/axios-helpers";

import { BaseRecord } from "./types";
import { BaseQueryClient } from "./queries";

interface FormDrawerProps<T extends BaseRecord> {
  beforeSubmit?: (values: Partial<T>) => Promise<Partial<T>>;
  afterSubmit?: (record: T) => Promise<void>;
  initialValues?: FormProps<Partial<T>>["initialValues"];
  validationSchema: FormProps<Partial<T>>["validationSchema"];
  params?: {
    site_id?: number;
    controller_id?: number;
  };
  title?: string;
  subtitle?: string;
  QueryClient: BaseQueryClient<T>;
  children?: React.ReactNode;
}

export function FormDrawer<T extends BaseRecord>({
  beforeSubmit,
  afterSubmit,
  initialValues,
  validationSchema,
  params,
  title,
  subtitle,
  QueryClient,
  children,
}: FormDrawerProps<T>): React.ReactElement {
  const { activeRoute, reset, pop } = useAppDrawer();

  const [create] = QueryClient.useCreateMutation();
  const [update] = QueryClient.useUpdateMutation();
  const history = useHistory();

  const handleSubmit = useCallback<FormOnSubmit<Partial<T>>>(
    async (values, helpers) => {
      let response = null;
      const updatedValues = beforeSubmit ? await beforeSubmit(values) : values;
      try {
        if (updatedValues?.id) {
          response = await update({
            id: updatedValues.id,
            values: updatedValues as T,
          });
        } else {
          response = await create({ values: updatedValues });

          if (QueryClient.createRedirectPath) {
            const redirectPath = QueryClient.createRedirectPath(
              response,
              params
            );
            if (redirectPath) {
              history.push(redirectPath);
            }
          }
        }
        pop();

        if (afterSubmit) {
          await afterSubmit(response as T);
        }
      } catch (error) {
        // returns null if its not a solicit error
        const errors = getFormFieldsErrors<T>(error);

        // Display form errors from API responses, keeping drawer open
        if (errors) {
          errors.forEach((error) => {
            helpers.setFieldError(error.field, error.description);
          });
        } else {
          pop();
        }
      }
    },
    [
      QueryClient,
      afterSubmit,
      create,
      history,
      params,
      pop,
      update,
      beforeSubmit,
    ]
  );

  return (
    <Form<Partial<T>>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Drawer open={!!activeRoute} onClose={reset} anchor="right">
        <DrawerHeader title={title} subtitle={subtitle} />
        <DrawerContent>{children}</DrawerContent>

        <DrawerActions>
          <Button variation="outlined" onPress={pop}>
            Cancel
          </Button>
          <FormSubmit label="Save" />
        </DrawerActions>
      </Drawer>
    </Form>
  );
}
