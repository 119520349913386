import { Route } from "badmagic/dist/types";

import { TempModeDurationOptions } from "@/modules/door/types";

import { booleanOptions, paginationParams } from "../shared";

export const apiRoutes: Route[] = [
  // ACCESS LEVELS
  {
    name: "List Access Levels",
    path: "/access-levels",
    documentation: "List all access levels available.",
  },
  // CREDENTIAL BANKS
  {
    name: "List Credential Banks",
    path: "/credential-banks",
    documentation: "List all credential banks available.",
  },
  // CREDENTIALS
  {
    name: "View Credential",
    path: "/credentials/:credential_id",
    documentation: "View a credential.",
  },
  {
    name: "Update Credential",
    method: "PUT",
    path: "/credentials/:credential_id",
    body: [
      { name: "status" },
      { name: "pending_delete" },
      { name: "number" },
      { name: "type" },
      { name: "remote_id" },
      {
        name: "start_at",
      },
      {
        name: "end_at",
      },
      {
        name: "pin",
      },
      {
        name: "pin_length",
      },
    ],
    documentation: "Update a credential.",
  },
  {
    name: "Encode Credential",
    method: "POST",
    path: "/credentials/:credential_id/encode",
    documentation: "Encode a credential.",
  },
  // DOORS
  {
    name: "View Door",
    path: "/doors/:door_id",
    documentation: "View a door.",
  },
  {
    name: "List Doors",
    path: "/doors",
    documentation: "List doors.",
    qsParams: [
      { name: "controller_id" },
      { name: "member_id" },
      { name: "name" },
      { name: "door_type" },
      ...paginationParams([
        { value: "name", label: "Door Name" },
        { value: "door_type", label: "Door Type" },
      ]),
    ],
  },
  {
    name: "Update Door Mode",
    method: "POST",
    path: "/doors/:door_id/mode",
    body: [
      {
        name: "mode",
        options: [
          { label: "None", value: "none" },
          { label: "Disabled", value: "disabled" },
          { label: "Unlocked", value: "unlocked" },
          { label: "Facility Code Only", value: "facility_code_only" },
          { label: "Card Only", value: "card_only" },
          { label: "Pin Only", value: "pin_only" },
          { label: "Card and Pin", value: "card_and_pin" },
          { label: "Card or Pin", value: "card_or_pin" },
        ],
      },
      { name: "duration", options: TempModeDurationOptions },
    ],
    documentation: "Update a door's mode.",
  },
  {
    name: "List Doors For Members",
    path: "/members/:member_id/doors",
    documentation: "List doors assigned to a member.",
  },
  // DOORS + MEMBERS
  {
    name: "Unassign Door from Member",
    method: "DELETE",
    path: "/members/:member_id/doors/:door_id",
    documentation: "Unassign a door from a member.",
  },
  {
    name: "Assign Door to Member",
    method: "POST",
    path: "/members/:member_id/doors/:door_id",
    documentation: "Assign a door to a member.",
  },
  // EVENTS
  {
    name: "List Events",
    path: "/events",
    qsParams: [
      { name: "date" },
      { name: "type" },
      { name: "door_id" },
      { name: "credential_id" },
      { name: "door_name" },
      { name: "member_name", placeholder: "Searches by first or last name" },
      { name: "credential_type" },
      { name: "credential_number" },
      { name: "pass_id" },
      { name: "device_id" },
      { name: "start_datetime", placeholder: "2022-06-22 18:00:00.000000Z" },
      { name: "end_datetime", placeholder: "2022-06-23 20:00:00.000000Z" },
      ...paginationParams([
        { label: "Type", value: "type" },
        { label: "Code", value: "code" },
        { label: "First Name", value: "first_name" },
        { label: "Last Name", value: "last_name" },
        { label: "Happened At", value: "happened_at" },
      ]),
    ],
    documentation: "List events for site.",
  },
  // LOCKS
  {
    name: "View Lock",
    path: "/locks/:lock_id",
    documentation: "View a lock.",
  },
  {
    name: "List Locks",
    path: "/locks",
    qsParams: [
      { name: "serial", placeholder: "Lock identifier (serial, id, etc)" },
      {
        name: "type",
        options: [
          { label: "Level", value: "level" },
          { label: "Salto", value: "salto" },
          { label: "Schlage", value: "schlage" },
        ],
      },
      ...paginationParams(),
    ],
    documentation: "List locks for site.",
  },
  // MEMBERS
  {
    name: "View Member",
    path: "/members/:member_id",
    documentation: "View a member.",
  },
  {
    name: "List Members",
    path: "/members",
    qsParams: [{ name: "access_level_id" }, ...paginationParams()],
    documentation: "List all members for site.",
  },
  {
    name: "Create Member",
    method: "POST",
    path: "/members",
    body: [
      { name: "first_name", required: true },
      { name: "last_name", required: true },
      { name: "department" },
      { name: "email_address" },
      { name: "location" },
      { name: "phone_number" },
      { name: "remote_id" },
      { name: "title" },
      { name: "nickname" },
      { name: "hide_from_directory", options: booleanOptions },
    ],
    documentation: "Create a member.",
  },
  {
    name: "Update Member",
    method: "PUT",
    path: "/members/:member_id",
    body: [
      { name: "first_name", required: true },
      { name: "last_name", required: true },
      { name: "department" },
      { name: "email_address" },
      { name: "location" },
      { name: "phone_number" },
      { name: "remote_id" },
      { name: "title" },
      { name: "nickname" },
      {
        name: "communication_preference",
        options: [
          { label: "None", value: "none" },
          { label: "Video", value: "video" },
          { label: "Voice", value: "voice" },
          { label: "Both", value: "both" },
        ],
      },
    ],
    documentation: "Update a member.",
  },
  {
    name: "Delete Member",
    method: "DELETE",
    path: "/members/:member_id",
    documentation: "Delete a member.",
  },
  {
    name: "List Member's Access Levels",
    path: "/members/:member_id/access-levels",
    documentation: "List a member's access levels.",
  },
  {
    name: "Assign Member To Access Level",
    method: "POST",
    path: "/members/:member_id/access-levels/:access_level_id",
    documentation: "Assign a member to an access level.",
  },
  {
    name: "Unassign Member From Access Level",
    method: "DELETE",
    path: "/members/:member_id/access-levels/:access_level_id",
    documentation: "Unassign a member from an access level.",
  },
  {
    name: "List Credentials For Member",
    path: "/members/:member_id/credentials",
    documentation: "List credentials for member.",
  },
  {
    name: "Create Credential For Member",
    method: "POST",
    path: "/members/:member_id/credentials",
    body: [
      {
        name: "type",
        required: true,
        options: [
          { label: "Pin", value: "pin" },
          { label: "Card", value: "card" },
          { label: "Mobile", value: "mobile" },
        ],
      },
      { name: "start_at", placeholder: "2022-06-22 20:00:00.000000Z" },
      { name: "end_at", placeholder: "2022-06-23 20:00:00.000000Z" },
      { name: "card_format_id" },
      { name: "pin" },
      { name: "reference_id" },
    ],
    documentation:
      "A credential is used to authenticate who is trying to gain entry. This can include PIN or card based credentials.",
  },
  {
    name: "Delete Credential For Member",
    method: "DELETE",
    path: "/members/:member_id/credentials/:credential_id",
    documentation:
      "Delete a credential (card will be avaliable to be used again).",
  },
  {
    name: "View Member By Remote Id",
    path: "/members/:remote_id/remote",
    documentation: "View member by remote_id.",
  },
  // PASSES
  {
    name: "View Pass For Member",
    path: "/members/:member_id/passes/:pass_id",
    documentation: "View a member's pass.",
  },
  {
    name: "List Passes For Member",
    path: "/members/:member_id/passes",
    qsParams: paginationParams(),
    documentation: "List passes assigned to member.",
  },
  {
    name: "Create Pass For Member",
    method: "POST",
    path: "/members/:member_id/passes",
    body: [
      { name: "name" },
      { name: "location" },
      { name: "device_id" },
      { name: "start_at", placeholder: "2022-06-22 20:00:00.000000Z" },
      { name: "end_at", placeholder: "2022-06-23 20:00:00.000000Z" },
    ],
    documentation: "Create a pass for a member.",
  },
  {
    name: "Delete Pass For Member",
    method: "DELETE",
    path: "/members/:member_id/passes/:pass_id",
    documentation: "Delete a member's pass.",
  },
  // SITES
  {
    name: "View Site",
    path: "/site",
    documentation: "Site details for current API credentials.",
  },
];
