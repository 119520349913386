import { BadMagic } from "badmagic";

import { internalApiWorkspace } from "./workspaces/internal-api";
import { HistoryMetadata } from "./plugins/HistoryMetadata";
import { AuthForm } from "./plugins/AuthForm";
import { applyAxiosInterceptors } from "./plugins/apply-axios-interceptors";
import { apiWorkspace } from "./workspaces/api";
import { unauthenticatedWorkspace } from "./workspaces/unauthenticated";
import { passWorkspace } from "./workspaces/pass";

export const BadMagicClient = () => {
  return (
    <BadMagic
      AuthForm={AuthForm}
      HistoryMetadata={HistoryMetadata}
      applyAxiosInterceptors={applyAxiosInterceptors}
      workspaces={[
        apiWorkspace,
        internalApiWorkspace,
        passWorkspace,
        unauthenticatedWorkspace,
      ]}
    />
  );
};
