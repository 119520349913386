import { useState } from "react";

import { Button, Table, VStack, useToast, Typography } from "@smartrent/ui";

import { Controller } from "@/modules/controller/types";
import { apiClient } from "@/lib/api";
import { useAppDrawer } from "@/components/layout/AppDrawer";
import { Reader, LEDColor, ValidSubmitKeys } from "@/modules/reader/types";
import { Panel } from "@/modules/panel/types";
import { QueryKeys } from "@/types";
import { ReaderDrawerProps } from "@/modules/reader/Drawer";

interface ControllerOsdpProps {
  controller: Controller;
}

interface AddressData {
  address: number;
  associated_reader: undefined | Reader;
  serial: string;
}

export const buildAddressOptions = (
  addressData: AddressData[],
  readerAddress: number
) => {
  const usedAddresses = addressData
    .filter(({ address }: AddressData) => address !== readerAddress)
    .map(({ address }: { address: number }) => address);

  return (
    Array.from(Array(127).keys())
      // 83 is a reserved OSDP address, it should never be chosen as an address
      .filter((n) => n !== 83 && !usedAddresses.includes(n))
      .map((n) => ({ label: n, value: n }))
  );
};

export const ControllerOsdpDisplay = ({ controller }: ControllerOsdpProps) => {
  const setToast = useToast();
  const drawer = useAppDrawer();
  const [addressData, setAddressData] = useState<AddressData[]>([]);
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const getAddressData = () => {
    // clear previous scan data
    setAddressData([]);
    setIsScanning(true);
    setToast({
      message: "This could take a minute",
      title: "Starting Scan",
      status: "knowledge",
    });
    apiClient
      .get(`v1/controllers/${controller.id}/osdp_scan`)
      .then((response) => {
        setAddressData(response.data.address_data);
        setIsScanning(false);
      })
      .catch((error) => {
        setIsScanning(false);
        setToast({
          message: error.response.data.errors[0],
          status: "error",
          title: "Error",
        });
      });
  };

  return (
    <VStack>
      <Table<AddressData>
        title="Connected Readers"
        data={addressData}
        columns={[
          {
            name: "address",
            header: "Address",
            render: ({ row }) => <Typography>{row.address}</Typography>,
          },
          {
            name: "serial",
            header: "Serial",
            render: ({ row }) => <Typography>{row.serial}</Typography>,
          },
          {
            name: "readerName",
            header: "Associated Reader Name",
            render: ({ row }) => (
              <Typography>{row.associated_reader?.name || "N/A"}</Typography>
            ),
          },
        ]}
        onRowPress={(row) => {
          const addressOptions = buildAddressOptions(addressData, row.address);

          if (row.associated_reader) {
            drawer.push(QueryKeys.ReadersWarbler, {
              addressOptions,
              reader: row.associated_reader,
              afterSubmit: () => setAddressData([]),
            } as ReaderDrawerProps);
          } else {
            const panel = controller.panels[0] as Panel;
            const props = {
              addressOptions,
              // Default values for Warbler reader
              reader: {
                address: row.address,
                original_address: row.address,
                led_color: LEDColor.red,
                submit_key: ValidSubmitKeys[0],
                pin_digits: 8,
                panel: { ...panel, controller: controller },
              } as Reader,
              afterSubmit: () => setAddressData([]),
            } as ReaderDrawerProps;

            drawer.push(QueryKeys.ReadersWarbler, props);
          }
        }}
        action={
          <Button isLoading={isScanning} onPress={getAddressData}>
            Scan
          </Button>
        }
      />
    </VStack>
  );
};
