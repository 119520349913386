import { View, StyleSheet } from "react-native";
import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { useCallback } from "react";
import {
  Button,
  DrawerActions,
  DrawerContent,
  FormikSubmit,
} from "@smartrent/ui";

import { MemberQueries } from "@/modules/member/queries";
import { useAppDrawer } from "@/components/layout/AppDrawer";
import { AccessLevel, MemberAccessLevel } from "@/modules/access-level/types";
import { AccessLevelMultiSelectField } from "@/modules/access-level/components/AccessLevelSelectField";

import { Member } from "../types";

const validationSchema = yup.object().shape({
  member_id: yup.number().required(),
  access_level_ids: yup.array().of(yup.number()).required(),
});

interface MemberAccessLevelFormProps {
  initialValues?: Member;
  params: {
    site_id: number;
  };
}

export const MemberAccessLevelForm = ({
  initialValues,
  params,
}: MemberAccessLevelFormProps) => {
  const [assignAccessLevel] = MemberQueries.useAssignAccessLevelsMutation();
  const drawer = useAppDrawer();

  const handleBack = useCallback(() => {
    drawer.pop();
  }, [drawer]);

  const handleSubmit = useCallback(
    async (
      values: MemberAccessLevel,
      formikHelpers: FormikHelpers<MemberAccessLevel>
    ) => {
      assignAccessLevel(values);
      handleBack();
    },
    [assignAccessLevel, handleBack]
  );

  return (
    <View style={styles.root}>
      <Formik<MemberAccessLevel>
        initialValues={{
          member_id: initialValues?.id,
          access_level_ids: initialValues?.access_levels
            ? initialValues.access_levels.map(
                (access_level: AccessLevel) => access_level.id
              )
            : [],
        }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {() => (
          <>
            <DrawerContent>
              <Form>
                <AccessLevelMultiSelectField
                  name={"access_level_ids"}
                  label={"Access Levels"}
                  initialValues={initialValues?.access_levels || []}
                  site_id={params.site_id}
                />
              </Form>
            </DrawerContent>
            <DrawerActions>
              <Button onPress={handleBack} variation={"outlined"}>
                Cancel
              </Button>
              <FormikSubmit
                label="Save"
                submittingLabel="Saving..."
                size="small"
              />
            </DrawerActions>
          </>
        )}
      </Formik>
    </View>
  );
};

// TODO: Make this not suck
const styles = StyleSheet.create({
  root: { flexGrow: 1 },
});
