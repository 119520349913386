import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { HolidaySchedule } from "./types";

const queryKey = QueryKeys.HolidaySchedules;

class HolidayScheduleClient extends BaseQueryClient<HolidaySchedule> {
  constructor() {
    super({ queryKey });
  }
}

export const HolidayScheduleQueries = new HolidayScheduleClient();
