import {
  Typography,
  Button,
  FilterProps,
  FormikSelectField,
  ListQueryResponse,
  Table,
  useTableQuery,
} from "@smartrent/ui";

import { useModalState } from "@smartrent/hooks";

import { View } from "react-native";

import { useQueryClient } from "@tanstack/react-query";

import { useCallback, useState } from "react";

import { useParams } from "react-router-dom";

import {
  StatusDisplay,
  SyncStatus,
} from "@/components/alloy-access/StatusDisplay";
import { DATE_TIME_FORMAT, formatDateTime } from "@/lib/formatters";

import { humanize } from "@/lib/helpers";

import { booleanOptions } from "@/pages/dev/badmagic/workspaces/shared";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { QueryKeys } from "@/types";

import { Paths } from "@/lib/path";

import { DomLink } from "@/components/alloy-access/Link";
import { useDialog } from "@/context/dialog";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { useChannel } from "@/hooks/useChannel";

import { useSocketChannelEvent } from "@/hooks/useSocketChannelEvent";

import { Messages, siteEncodingTopic } from "@/lib/socket";

import { SiteQueries } from "../site/queries";

import { SystemTypes } from "../site/types";

import { Member } from "../member/types";

import { createActionsColumn, CreateButton } from "../base/table/utils";

import { Credential, CredentialType, SitesWithEncodableFobs } from "./types";

import { CredentialQueries } from "./queries";

import { CredentialLostOrReturnedDialog } from "./components/LostOrReturnedDialog";

interface CredentialTableFilters extends TableFilters {
  site_id: number;
  member_id: number;
}

interface CredentialsTableProps {
  filters: CredentialTableFilters;
  member: Member;
}

export const CredentialsTable = ({
  filters,
  member,
}: CredentialsTableProps) => {
  const { data: site } = SiteQueries.useQuery({ id: Number(filters.site_id) });
  const [doDelete] = CredentialQueries.useDeleteMutation();
  const { confirm } = useDialog();
  const params = useParams();
  const drawer = useAppDrawer();

  const [deletedCredential, setCredential] = useState<Credential | null>(null);

  const { visible, onOpen, onClose } = useModalState();

  const { canView, canCreate, canDelete } = usePermissions(CredentialQueries);

  const encodeCredentialAction = useCallback(
    (credential: Credential) => {
      if (site) {
        drawer.push(QueryKeys.EncodeCredential, {
          params: filters,
          initialValues: { credential, member, site },
        });
      }
    },
    [drawer, filters, member, site]
  );

  const [siteEncodingChannel] = useChannel(siteEncodingTopic(filters.site_id));
  const queryClient = useQueryClient();

  useSocketChannelEvent(
    siteEncodingChannel,
    Messages.encoding_updated,
    (message) => {
      if (message.status == "success") {
        queryClient.invalidateQueries([CredentialQueries.queryKey]);
      }
    }
  );

  const tableProps = useTableQuery<
    any,
    Credential,
    ListQueryResponse<Credential>
  >({
    fetch: CredentialQueries.fetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      CredentialQueries.queryKey,
      {},
      {
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "type",
        header: "Type",
        render: ({ row }) => {
          return <Typography type="body">{humanize(row.type)}</Typography>;
        },
      },
      {
        name: "number",
        header: "Number",
        render: ({ row }) => {
          if (row.type == CredentialType.pin) {
            return <Typography type="body">{row.pin}</Typography>;
          }
          if (row.type == CredentialType.card) {
            return (
              <Typography type="body">
                {row.number} (FC:{row.card_format?.facility_code})
              </Typography>
            );
          }
          return <View></View>;
        },
      },
      {
        name: "door",
        header: "Door",
        render: ({ row }) => {
          if (row.door) {
            return (
              <DomLink href={Paths.doorViewPath(filters.site_id, row.door.id)}>
                {row.door.name}
              </DomLink>
            );
          } else {
            return <View></View>;
          }
        },
      },
      {
        name: "duration",
        header: "Duration",
        render: function NameRow({ row }) {
          return (
            <View>
              {row.start_at && (
                <Typography type="body">
                  {formatDateTime(
                    row.start_at,
                    site?.timezone,
                    DATE_TIME_FORMAT
                  )}
                </Typography>
              )}
              {row.end_at ? (
                <Typography type="body">
                  {formatDateTime(row.end_at, site?.timezone, DATE_TIME_FORMAT)}
                </Typography>
              ) : (
                <Typography type="body">Permanent</Typography>
              )}
            </View>
          );
        },
      },
      {
        name: "status",
        header: "Sync Status",
        render: ({ row }) => {
          const credentialControllers = row.controllers || [];
          let unsyncedCount = 0;
          for (const credentialController of credentialControllers) {
            if (!credentialController.synced_at) unsyncedCount++;
          }

          if (row.pending_delete) {
            return (
              <StatusDisplay
                status={
                  unsyncedCount == 0 ? SyncStatus.Synced : SyncStatus.NotSynced
                }
                message={`Pending Delete (${unsyncedCount} Unsynced)`}
              />
            );
          } else {
            return (
              <StatusDisplay
                status={
                  unsyncedCount == 0 ? SyncStatus.Synced : SyncStatus.NotSynced
                }
                message={`${unsyncedCount} Unsynced`}
              />
            );
          }
        },
      },
      {
        name: "custom_actions",
        header: "",
        hidden: !SitesWithEncodableFobs.includes(
          site?.system_type as SystemTypes
        ),
        render: function NameRow({ row }) {
          const credentialControllers = row.controllers || [];

          const shouldHide =
            // Only show the encode button if it's a card credential.
            row.type != CredentialType.card ||
            // Hide the button on Schlage sites if it was already encoded (marked as synced).
            (site?.system_type == SystemTypes.Schlage &&
              credentialControllers.every((cc) => !!cc.synced_at));

          if (shouldHide) {
            return null;
          } else {
            return (
              <Button
                onPress={() => {
                  encodeCredentialAction(row);
                }}
              >
                Encode
              </Button>
            );
          }
        },
      },
      {
        name: "include_deleted",
        hidden: true,
        header: "Include Deleted",
        filter: (props: FilterProps) => {
          return <FormikSelectField {...props} options={booleanOptions} />;
        },
      },
      createActionsColumn<Credential>({
        shouldAdjustForPressIcon: canView,
        canDelete,
        onDelete: async (credential) => {
          if (
            site?.system_type === SystemTypes.Schlage &&
            credential.type == "card"
          ) {
            setCredential(credential);
            onOpen();
            return;
          }
          const confirmed = await confirm({
            title: "Delete",
            description: "Are you sure you want to delete this Credential?",
            confirmText: "Delete",
            confirmType: "destructive",
            cancelText: "Cancel",
          });
          if (confirmed) {
            await doDelete({ id: credential.id });
          }
        },
      }),
    ],
    defaultPageSize: 10,
  });
  return (
    <>
      <Table<Credential>
        title={""}
        noRecordsText={`No Credentials Found`}
        action={
          <CreateButton
            canCreate={canCreate}
            onCreate={() => drawer.push(CredentialQueries.queryKey, { params })}
            text={"Add Credential"}
          />
        }
        onRowPress={
          canView
            ? (row) =>
                drawer.push(CredentialQueries.queryKey, {
                  initialValues: row,
                  params: filters,
                })
            : undefined
        }
        {...tableProps}
      />
      <CredentialLostOrReturnedDialog
        visible={visible}
        onClose={onClose}
        credential={deletedCredential}
      />
    </>
  );
};
