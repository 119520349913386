import {
  Button,
  DrawerActions,
  DrawerContent,
  SelectField,
  StaticTextField,
  Typography,
  VStack,
} from "@smartrent/ui";

import { createRef, useState } from "react";

import { StyleSheet } from "react-native";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import { ElevatorQueries } from "../elevator/queries";
import { Elevator } from "../elevator/types";

import { Site } from "../site/types";

import { AccessLevel } from "../access-level/types";

import { DoorConfigurations } from "../door/types";

import { ElevatorAccessLevelForm } from "./Form";
import { AddFloorsForm } from "./components/AddFloorForm";

interface ElevatorAccessLevelDrawerProps {
  initialValues?: Elevator;
  params: {
    access_level: AccessLevel;
    site: Site;
  };
}

export const ElevatorAccessLevelDrawer: React.FC<
  React.PropsWithChildren<ElevatorAccessLevelDrawerProps>
> = ({ initialValues, params }) => {
  const drawer = useAppDrawer();
  const [elevator, setElevator] = useState<Elevator | undefined>(initialValues);
  const submitButtonRef = createRef<HTMLButtonElement>();

  const elevatorQuery = ElevatorQueries.useList({
    site_id: params.site.id,
    not_in_access_level_id: params.access_level.id,
    include_deleted: false,
  });

  const selectElevatorQuery = ElevatorQueries.select({
    filterParams: {
      site_id: params.site.id,
      not_in_access_level_id: params.access_level.id,
      include_deleted: false,
    },
    initialValue: elevator?.id?.toString() || undefined,
  });

  return (
    <>
      <DrawerContent>
        <VStack spacing={16}>
          {initialValues?.name ? (
            <StaticTextField
              value={initialValues.name}
              label={"Elevator"}
              onPress={() => null}
              disabled={true}
            />
          ) : elevatorQuery?.data?.total_records ? (
            <SelectField<Elevator>
              required
              onChange={(value) => setElevator(value)}
              name="elevator"
              label="Elevator"
              getOptionValue={(option) => option}
              getOptionLabel={(option) => `${option.name}`}
              value={elevator}
              options={selectElevatorQuery.options}
              flatListProps={{
                ...selectElevatorQuery.flatListProps,
                keyExtractor: (item, index) =>
                  `${item.id}-${index}-elevator-opt`, // fixes console errors
              }}
            />
          ) : (
            <VStack spacing={24} align="center">
              <Typography style={styles.textCenter}>
                You cannot add an elevator to{" "}
                <Typography type="bodySemibold">
                  {params?.access_level?.name}{" "}
                </Typography>
                because no elevators exist for{" "}
                <Typography type="bodySemibold">
                  {params?.site?.name}
                </Typography>
              </Typography>
              <Button
                onPress={() =>
                  drawer.push(ElevatorQueries.queryKey, {
                    params: { site_id: params.site.id },
                    initialValues: {
                      id: 0,
                      configuration: DoorConfigurations.elevatorWithFloor,
                    },
                  })
                }
              >
                Add New Elevator
              </Button>
            </VStack>
          )}

          {elevator ? (
            elevator?.floors?.length ? (
              <ElevatorAccessLevelForm
                initialValues={initialValues}
                elevator={elevator}
                site={params.site}
                access_level={params.access_level}
                submitButtonRef={submitButtonRef}
              />
            ) : (
              <AddFloorsForm
                door_id={elevator.id}
                onSuccessfulSubmit={setElevator}
              />
            )
          ) : null}
        </VStack>
      </DrawerContent>
      <DrawerActions>
        <Button variation="outlined" onPress={() => drawer.pop()}>
          Cancel
        </Button>
        {/* This is a hack to click a hidden button within the js-shared Form component
            We currently don't have access to requestSubmit() via a hook
            We can remove this once js-shared team publishes hook we can use to accomplish submitting form outside the form context
            Note: I still can't believe this works as well it does on local
                  so figure its worth a try as a temporary solution.

            Created Js-shared issue for this:
            https://smartrent.atlassian.net/browse/SKI-78
            */}
        <Button
          disabled={!elevator || !elevator?.floors?.length}
          onPress={(e) =>
            submitButtonRef?.current
              ? submitButtonRef.current.click()
              : e.preventDefault()
          }
        >
          Save
        </Button>
      </DrawerActions>
    </>
  );
};

const styles = StyleSheet.create({ textCenter: { textAlign: "center" } });
