import { useEffect } from "react";
import { Channel } from "phoenix";

export const useSocketChannelEvent = (
  channel: Channel,
  event: string,
  callback: (response?: any) => void
): void => {
  useEffect(() => {
    const ref = channel.on(event, callback);

    return () => {
      channel.off(event, ref);
    };
  }, [callback, channel, event]);
};
