import { useCallback, useContext, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";

import { useInterval } from "@smartrent/hooks";
import { Calendar, Close, InformationSolid } from "@smartrent/icons";
import { Button, Typography, VStack } from "@smartrent/ui";

import { Notification } from "@/modules/notifications/types";
import Sentry from "@/lib/sentry";
import { PageCtx } from "@/context/PageContext";
import { SiteQueries } from "@/modules/site/queries";

// Purposefully using this over localStorage or sessionStorage so we don't
// have to manage storage limits, envs which don't have localStorage (private browsing),
// or key removal (for localStorage).
const SEEN_NOTIFICATIONS = new Set<string>();

const FIVE_MINUTES = 5 * 60 * 1000;

/**
 * This function is called both on an interval from NotificationBanner and after any CRUD operation that might affect
 * notifications. Namely, creating, updating, or deleting a holiday schedule.
 *
 * This function is a global instead of a context because setting up a context from the NotificationBanner component
 * causes issues when you need to call it from _outside_ the site-level render tree. The prime example being
 * from a form rendered inside a Drawer for holiday schedules (<Drawer>s are rendered outside the tree for z-index reasons)
 */
export let fetchAndSetSiteNotifications: () => Promise<void> = async () => {
  /* No-op until the notification banner renders */
};

export const NotificationBanner: React.FC = () => {
  const [activeNotification, setActiveNotification] = useState<Notification>();
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const pageContext = useContext(PageCtx);

  const fetchAndSetNotifications = useCallback(async () => {
    // We currently only fetch site-based notifications
    if (!pageContext.site?.id) {
      setNotifications([]);
      setActiveNotification(undefined);
      return;
    }

    const notifications = await SiteQueries.getNotifications(
      pageContext.site.id
    ).then((notifications) => {
      return notifications.filter((notification) => {
        return !SEEN_NOTIFICATIONS.has(notification.key);
      });
    });

    setNotifications(notifications);

    setActiveNotification(notifications[0]);
  }, [pageContext.site]);

  useEffect(() => {
    fetchAndSetNotifications();

    fetchAndSetSiteNotifications = fetchAndSetNotifications;

    return () => {
      fetchAndSetSiteNotifications = async () => {
        /* No-op */
      };
    };
  }, [fetchAndSetNotifications]);

  // Note: (kjb) this doesn't fetch immediately, hence the fetch above
  useInterval(fetchAndSetNotifications, FIVE_MINUTES);

  const onClose = useCallback(
    (notificationKey: Notification["key"]) => {
      SEEN_NOTIFICATIONS.add(notificationKey);
      const nextNotifications = notifications.filter(
        (notification) => notification.key != notificationKey
      );
      setNotifications(nextNotifications);
      setActiveNotification(nextNotifications[0]);
    },
    [notifications]
  );

  if (!activeNotification) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Icon notification={activeNotification} />
      <VStack spacing={4}>
        {!!activeNotification.items[0].title && (
          <Typography type="title5">
            {activeNotification.items[0].title}
          </Typography>
        )}
        {!!activeNotification.items[0].message && (
          <Typography type="captionLarge" style={styles.regularWeightFont}>
            {activeNotification.items[0].message}
          </Typography>
        )}
      </VStack>
      <Button
        onPress={() => onClose(activeNotification.key)}
        variation="plain"
        style={styles.closeButton}
      >
        <Close />
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: "#EBEEF4",
    borderBottomWidth: 1,
    borderBottomColor: "#BEC2CD",
    flexDirection: "row",
    gap: 20,
    height: 84,
    padding: 20,
  },
  closeButton: {
    marginLeft: "auto",
  },
  regularWeightFont: {
    fontWeight: "400",
  },
});

function Icon({ notification }: { notification: Notification }) {
  switch (notification.icon) {
    case "calendar":
      return <Calendar />;
    default:
      Sentry.captureMessage(
        `Unknown icon type for notification with key: ${notification.key} Icon: ${notification.icon}. `
      );
      return <InformationSolid />;
  }
}
