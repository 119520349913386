import {
  VStack,
  FormikTextInputField,
  FormikNumberInputField,
  FormikSelectField,
  FormikSlider,
  FormikCheckbox,
} from "@smartrent/ui";
import { FormikProps, Form } from "formik";
import * as yup from "yup";

import { BaseForm } from "@/modules/base/Form";
import { Controller } from "@/modules/controller/types";
import { FeatureFlagQueries } from "@/modules/feature-flags/queries";
import { Input } from "@/modules/input/types";
import { Output } from "@/modules/output/types";
import { Panel } from "@/modules/panel/types";
import { Reader } from "@/modules/reader/types";
import { ScheduleSelectField } from "@/modules/schedule/components/ScheduleSelectField";

import {
  ValidDoorConfigurations,
  DoorModeOptions,
  StrikeModeOptions,
  Door,
  getDoorModeAssistiveText,
} from "../types";
import { DoorQueries } from "../queries";

interface DoorFormProps {
  initialValues: Door;
  site_id: number;
  controller: Controller;
}

const validationSchema = yup.object().shape({
  name: yup.string().max(40).required().label("Name"),
  default_mode: yup.string().required(),
  controller_id: yup.number().required("You must select a controller."),
  configuration: yup
    .string()
    .required("You must select a configuration.")
    .oneOf(ValidDoorConfigurations),
  strike_min: yup.number().nullable().min(0).max(500).label("Strike Min"),
  strike_max: yup.number().nullable().min(0).max(500).label("Strike Max"),
});

export const EditDoorForm = ({
  initialValues,
  site_id,
  controller,
}: DoorFormProps) => {
  const { data: flags, isLoading } = FeatureFlagQueries.useList({
    name: "door_position_switches",
  });

  const showDSM = isLoading || flags?.records.some((flag) => flag.enabled);

  const availableReaders: () => Array<{
    label: string;
    value: number;
  }> = () => {
    if (!controller) return [];
    if (!controller?.panels) return [];
    return controller.panels
      .map((panel: Panel) => {
        if (!panel?.readers) return [];
        return panel.readers.map((reader: Reader) => ({
          value: reader.id,
          label: `${panel.name}: ${reader.name}`,
        }));
      })
      .flat();
  };

  const availableOutputs: () => Array<{
    label: string;
    value: number;
  }> = () => {
    if (!controller) return [];
    if (!controller?.panels) return [];
    return controller.panels
      .map((panel: Panel) => {
        if (!panel?.outputs) return [];
        return panel.outputs.map((output: Output) => ({
          value: output.id,
          label: `${panel.name}: ${output.name}`,
        }));
      })
      .flat();
  };

  const availableInputs: () => Array<{ label: string; value: number }> = () => {
    if (!controller) return [];
    if (!controller?.panels) return [];
    return controller.panels
      .map((panel: Panel) => {
        if (!panel?.inputs) return [];
        return panel.inputs.map((input: Input) => ({
          value: input.id,
          label: `${panel.name}: ${input.name}`,
        }));
      })
      .flat();
  };

  return (
    <BaseForm<Door>
      initialValues={initialValues}
      validationSchema={validationSchema}
      QueryClient={DoorQueries}
    >
      {({ values }: FormikProps<Door>) => {
        return (
          <Form>
            <VStack spacing={8}>
              <FormikTextInputField name="name" label="Name" required />
              <FormikSelectField
                name="default_mode"
                label="Default Mode"
                options={DoorModeOptions}
                assistiveText={getDoorModeAssistiveText(values.default_mode)}
              />
              <FormikSelectField
                name="reader_id"
                label="Reader"
                options={availableReaders()}
              />
              <FormikSelectField
                name="strike_id"
                label="Strike"
                options={availableOutputs()}
              />
              <FormikSelectField
                name="strike_mode"
                label="Strike Mode"
                options={StrikeModeOptions}
              />
              <FormikNumberInputField name="strike_min" label="Strike Min" />
              <FormikNumberInputField name="strike_max" label="Strike Max" />
              <FormikSelectField
                name="rex1_id"
                label="Rex 1"
                options={availableInputs()}
              />
              <FormikSelectField
                name="rex2_id"
                label="Rex 2"
                options={availableInputs()}
              />
              <FormikSelectField
                name="dsm1_id"
                label="Door Contact"
                options={availableInputs()}
              />
              <ScheduleSelectField
                site_id={site_id}
                name="unlocked_schedule_id"
                label="Unlock Schedule"
              />
              {showDSM && (
                <>
                  <FormikSlider
                    name="held_open_limit_seconds"
                    assistiveText="Held Open Limit (mm:ss)"
                    min={1}
                    max={600}
                    step={30}
                    formatValue={(s) =>
                      new Date(s * 1000).toISOString().substring(14, 19)
                    }
                  />
                  <FormikCheckbox
                    name="disable_forced_open_events"
                    label="Disable Forced Open Events"
                  />
                </>
              )}
            </VStack>
          </Form>
        );
      }}
    </BaseForm>
  );
};
