import { Button, FlatListItemSeparator, Typography } from "@smartrent/ui";

import { useState } from "react";

import { View, StyleSheet } from "react-native";

import xmlFormat from "xml-formatter";

import { Controller } from "@/modules/controller/types";
import { Hub } from "@/modules/hub/types";
import {
  controllerChannelTopic,
  hubChannelTopic,
  Messages,
} from "@/lib/socket";
import { useChannel } from "@/hooks/useChannel";
import { useSocketChannelEvent } from "@/hooks/useSocketChannelEvent";
import { LoadingIndicator } from "@/components/LoadingIndicator";

interface ControllerRepliesDisplayProps {
  controller: Controller;
}

const ReplyListItem = ({ reply }: { reply: any }) => {
  if (!reply) {
    return <LoadingIndicator />;
  }

  return <Typography type="caption">{reply}</Typography>;
};

const maybeFormat = (response: { response: string }) => {
  if (response.response?.includes("<?xml")) {
    return xmlFormat(response.response);
  } else {
    return JSON.stringify(response, null, 2);
  }
};

export const ControllerAndHubRepliesPanel = ({
  controller,
}: ControllerRepliesDisplayProps) => {
  const [controllerChannel] = useChannel(controllerChannelTopic(controller));
  const [hubChannel] = useChannel(hubChannelTopic(controller.hub as Hub));
  const [replies, setReplies] = useState<Array<string>>([]);

  useSocketChannelEvent(
    controllerChannel,
    Messages.controller_reply,
    (response) => {
      const temp = replies.slice();
      setReplies([maybeFormat(response), ...temp]);
    }
  );

  useSocketChannelEvent(hubChannel, Messages.hub_reply, (response) => {
    const temp = replies.slice();
    setReplies([maybeFormat(response), ...temp]);
  });

  return (
    <View>
      <View style={styles.buttonContainer}>
        <Button onPress={() => setReplies([])}>Clear</Button>
      </View>
      {replies.map((reply: any, index: number) => {
        return (
          <View key={index} style={styles.replyContainer}>
            <ReplyListItem reply={reply} />
            <FlatListItemSeparator />
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    padding: 8,
  },
  replyContainer: {
    padding: 8,
  },
});
