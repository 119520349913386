import {
  useTableQuery,
  FilterProps,
  FormikSelectField,
  FormikTextInputField,
  ListQueryResponse,
  Table,
  Typography,
  useToast,
  VStack,
} from "@smartrent/ui";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { StyleSheet, View } from "react-native";
import { compact } from "lodash-es";

import { parseISO, addMinutes } from "date-fns";

import { useAppDrawer } from "@/components/layout/AppDrawer";

import {
  getErrorMessage,
  InternalAPIAxiosErrorResponse,
} from "@/lib/axios-helpers";
import { qsFromLocation } from "@/lib/helpers";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { DateTimeTypography } from "@/components/DateTimeTypography";

import {
  Site,
  SiteTimeZones,
  SystemTypes,
  TimeZoneOffsets,
} from "../site/types";

import { CredentialTypeOptions } from "../credential/types";

import { BaseRecord } from "../base/types";

import { ControllerQueries } from "../controller/queries";
import { Controller } from "../controller/types";

import { Door } from "../door/types";
import { DoorQueries } from "../door/queries";

import { Hub } from "../hub/types";
import { HubQueries } from "../hub/queries";

import { Member } from "../member/types";
import { MemberQueries } from "../member/queries";

import { UserQueries } from "../user/queries";
import { User } from "../user/types";

import { useTableUtils } from "../base/table/utils";

import DateRangePicker from "./DateRangePicker";

import { getEventDetails } from "./event-dictionary";

import { EventQueries } from "./queries";
import { Event, EventCodeOptions, EventTypeOptions } from "./types";

interface EventsTableProps {
  filters: EventsTableFilters;
  site: Site;
}

interface EventsTableFilters extends Partial<TableFilters & Event> {
  site_id: number;
}

export const EventsTable = ({ filters, site }: EventsTableProps) => {
  const drawer = useAppDrawer();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const [dateRange, setDateRange] = useState<{
    start_datetime?: string;
    end_datetime?: string;
  }>({ start_datetime: undefined, end_datetime: undefined });
  const setToast = useToast();
  const { numberOfLines } = useTableUtils();

  // NOTE(kjb) This exists because setToast can only be used from inside a component and EventQueries is a util file. On top
  // of that, the js-shared Table component expects a plain old fetch function instead of a hook, so we can't use the built
  // in validation of things like `BaseQueries.useList`. As such, we wrap the original fetch function to set a toast on failure.
  const customFetch = useCallback(
    async ({
      queryKey,
    }: {
      queryKey:
        | readonly unknown[]
        | readonly [string, Record<string, unknown>, TableFilters];
    }): Promise<ListQueryResponse<Event & BaseRecord>> => {
      try {
        return await EventQueries.fetch({ queryKey });
      } catch (error) {
        setToast({
          status: "error",
          message: `Failure: ${getErrorMessage(
            error as InternalAPIAxiosErrorResponse
          )}`,
        });
        return {
          current_page: 0,
          records: [],
          total_pages: 0,
          total_records: 0,
        };
      }
    },
    [setToast]
  );

  const shiftTimezone = useCallback(
    (date: string) => {
      if (!date) return undefined;
      const localOffset = new Date().getTimezoneOffset();
      const siteOffset = TimeZoneOffsets[site.timezone as SiteTimeZones];
      return addMinutes(parseISO(date), localOffset + siteOffset).toISOString();
    },
    [site.timezone]
  );

  useEffect(() => {
    const queryString = qsFromLocation(location);

    setDateRange({
      start_datetime: queryString.start_datetime
        ? shiftTimezone(queryString.start_datetime as string)
        : undefined,
      end_datetime: queryString.end_datetime
        ? shiftTimezone(queryString.end_datetime as string)
        : undefined,
    });
  }, [location, site.timezone, shiftTimezone]);

  const controllerSelectProps = ControllerQueries.tableSelectProps({
    defaultParams: { site_id: site.id },
  });

  const doorSelectProps = DoorQueries.tableSelectProps({
    defaultParams: { site_id: site.id },
  });

  const hubSelectProps = HubQueries.tableSelectProps({
    defaultParams: { site_id: site.id },
  });

  const memberSelectProps = MemberQueries.tableSelectProps({
    defaultParams: { site_id: site.id },
  });

  const userSelectProps = UserQueries.tableSelectProps({
    defaultParams: { site_id: site.id },
  });

  const { canView } = usePermissions(EventQueries);

  const tableProps = useTableQuery<any, Event, ListQueryResponse<Event>>({
    fetch: customFetch,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      DoorQueries.queryKey,
      {},
      {
        ...dateRange,
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: compact([
      {
        name: "details",
        header: "Details",
        render: ({ row }) => {
          const details = getEventDetails(row);

          return (
            <View>
              <VStack spacing={12}>
                <View>
                  <View style={styles.titleContainer}>
                    <details.icon
                      color={details.result}
                      size={20}
                      style={styles.icon}
                    />
                    <Typography
                      numberOfLines={numberOfLines}
                      style={styles.title}
                      type="title4"
                    >
                      {details.title}
                    </Typography>
                  </View>
                </View>
                <Typography
                  numberOfLines={numberOfLines}
                  type="bodySemibold"
                  color={details.result}
                  style={styles.subtitle}
                >
                  {details.subtitle}
                </Typography>
              </VStack>
            </View>
          );
        },
      },
      {
        name: "happened_at",
        header: "Happened At",
        render: ({ row }) => (
          <DateTimeTypography
            dateTime={row.happened_at}
            timezone={site.timezone}
          />
        ),
        sortable: true,
      },
      {
        name: "type",
        header: "Event Type",
        render: ({ row }) => {
          return (
            <Typography numberOfLines={numberOfLines}>{row.type}</Typography>
          );
        },
        hidden: true,
        filter: function TypeFilter(props: FilterProps) {
          return <FormikSelectField {...props} options={EventTypeOptions} />;
        },
      },
      {
        name: "code",
        header: "Result",
        render: ({ row }) => {
          return (
            <Typography numberOfLines={numberOfLines}>{row.code}</Typography>
          );
        },
        hidden: true,
        filter: function TypeFilter(props: FilterProps) {
          return <FormikSelectField {...props} options={EventCodeOptions} />;
        },
      },
      {
        name: "last_name",
        header: "Last Name",
        render: ({ row }) => {
          return (
            <Typography numberOfLines={numberOfLines}>
              {row.last_name}
            </Typography>
          );
        },
        hidden: true,
        filter: function NameFilter(props: FilterProps) {
          return (
            <FormikTextInputField
              assistiveText="Minimum 3 characters"
              {...props}
            />
          );
        },
      },
      {
        name: "first_name",
        header: "First Name",
        render: ({ row }) => {
          return (
            <Typography numberOfLines={numberOfLines}>
              {row.first_name}
            </Typography>
          );
        },
        filter: function NameFilter(props: FilterProps) {
          return (
            <FormikTextInputField
              assistiveText="Minimum 3 characters"
              {...props}
            />
          );
        },
        hidden: true,
      },
      {
        name: "member_id",
        header: "Member",
        filterType: {
          type: "selectField",
          getOptionValue: (option: Member) => {
            return option.id.toString();
          },
          getOptionLabel: (option: Member) => {
            return `${option.last_name}, ${option.first_name}`;
          },
          ...memberSelectProps,
        },
        hidden: true,
      },
      {
        name: "user_id",
        header: "User",
        filterType: {
          type: "selectField",
          getOptionValue: (option: User) => {
            return option.id.toString();
          },
          getOptionLabel: (option: User) => {
            return `${option.last_name}, ${option.first_name}`;
          },
          ...userSelectProps,
        },
        hidden: true,
      },
      {
        name: "controller_id",
        header: "Controller",
        filterType: {
          type: "selectField",
          getOptionValue: (option: Controller) => {
            return option.id.toString();
          },
          getOptionLabel: (option: Controller) => {
            return `${option.name}`;
          },
          ...controllerSelectProps,
        },
        hidden: true,
      },
      {
        name: "hub_id",
        header: "Hub",
        filterType: {
          type: "selectField",
          getOptionValue: (option: Hub) => {
            return option.id.toString();
          },
          getOptionLabel: (option: Hub) => {
            return `${option.serial} ${option.name ? `(${option.name})` : ""}`;
          },
          ...hubSelectProps,
        },
        hidden: true,
      },
      site.system_type === SystemTypes.Alloy
        ? {
            name: "door_id",
            header: "Door",
            filterType: {
              type: "selectField",
              getOptionValue: (option: Door) => {
                return option.id.toString();
              },
              getOptionLabel: (option: Door) => {
                return option.name || option.id.toString();
              },
              ...doorSelectProps,
            },
            hidden: true,
          }
        : null,
      {
        name: "credential_type",
        header: "Credential Type",
        filter: (props: FilterProps) => {
          return (
            <FormikSelectField options={CredentialTypeOptions} {...props} />
          );
        },
        hidden: true,
      },
    ]),
    defaultPageSize: 10,
  });
  return (
    <View>
      <View style={styles.dateRangePickerContainer}>
        <DateRangePicker location={location} history={history} />
      </View>
      <Table<Event>
        title={"Events"}
        noRecordsText={`No Events Found`}
        onRowPress={
          canView
            ? (row) =>
                drawer.push(EventQueries.queryKey, {
                  initialValues: row,
                  params,
                })
            : undefined
        }
        {...tableProps}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  titleContainer: { alignItems: "center", flexDirection: "row" },
  icon: { marginRight: 8 },
  title: {},
  subtitle: { fontSize: 16 },

  // This is a really dumb solution to a z-index problem.
  dateRangePickerContainer: {
    position: "absolute",
    right: 16,
    top: 72,
    zIndex: 9,
  },
});
