import { createDrawer } from "@/context/DrawerManager";

import { AccessLevelDrawer } from "@/modules/access-level/Drawer";
import { AccessLevelScheduleDrawer } from "@/modules/access-level-schedule/Drawer";
import { ApiApplicationDrawer } from "@/modules/api-applications/Drawer";
import { CardFormatDrawer } from "@/modules/card-format/Drawer";
import { ControllerDrawer } from "@/modules/controller/Drawer";
import { CredentialDrawer } from "@/modules/credential/Drawer";
import { CredentialEncodeDrawer } from "@/modules/credential/components/EncodeDrawer";
import { DoorDrawer } from "@/modules/door/Drawer";
import { ElevatorAccessLevelDrawer } from "@/modules/elevator-access-level/Drawer";
import { ElevatorAccessLevelDrawerHeader } from "@/modules/elevator-access-level/DrawerHeader";
import { EventDrawer } from "@/modules/event/Drawer";
import { FeatureFlagDrawer } from "@/modules/feature-flags/Drawer";
import { FirmwareDrawer } from "@/modules/firmware/Drawer";
import { HolidayScheduleDrawer } from "@/modules/holiday-schedules/Drawer";
import { HolidayScheduleGroupDrawer } from "@/modules/holiday-groups/Drawer";
import { HubDrawer } from "@/modules/hub/Drawer";
import { InputDrawer } from "@/modules/input/Drawer";
import { MemberAccessLevelDrawer } from "@/modules/member/components/MemberAccessLevelDrawer";
import { MemberDoorsDrawer } from "@/modules/member/components/MemberDoorsDrawer";
import { MemberDrawer } from "@/modules/member/Drawer";
import { OperatorDrawer } from "@/modules/operators/Drawer";
import { OrganizationDrawer } from "@/modules/organization/Drawer";
import { OutputDrawer } from "@/modules/output/Drawer";
import { PanelDrawer } from "@/modules/panel/Drawer";
import { ReaderDrawer } from "@/modules/reader/Drawer";
import { ScheduleDrawer } from "@/modules/schedule/Drawer";
import { ScheduleHolidayGroupsDrawer } from "@/modules/schedule-holiday-groups/Drawer";
import { ScheduleIntervalDrawer } from "@/modules/schedule-intervals/Drawer";
import { SipAccountDrawer } from "@/modules/sip-account/Drawer";
import { SiteDrawer } from "@/modules/site/Drawer";
import { SiteImportDrawer } from "@/modules/site-import/Drawer";
import { UserDrawer } from "@/modules/user/Drawer";

import { QueryKeys } from "@/types";
import { ElevatorFormDrawer } from "@/modules/elevator/ElevatorFormDrawer";
import { FloorFormDrawer } from "@/modules/floor/FormDrawer";
import { ReaderFormDrawer } from "@/modules/reader/ReaderFormDrawer";
import { UserActivityLogDrawer } from "@/modules/user-activity-logs/UserActivityLogDrawer";

// TODO: Refactor to use 'drawer' prop for all drawers, except for Warbler Readers
// See https://smartrent.atlassian.net/browse/AA-2045 for more details
const { Provider: AppDrawer, useDrawerNav: useAppDrawer } = createDrawer(
  "AppDrawer",
  {
    routes: {
      [QueryKeys.Members]: { component: MemberDrawer },
      [QueryKeys.Organizations]: { component: OrganizationDrawer },
      [QueryKeys.Firmware]: { component: FirmwareDrawer },
      [QueryKeys.Users]: { component: UserDrawer },
      [QueryKeys.Sites]: { component: SiteDrawer },
      [QueryKeys.Controllers]: { component: ControllerDrawer },
      [QueryKeys.ApiApplications]: { component: ApiApplicationDrawer },
      [QueryKeys.Hubs]: { component: HubDrawer },
      [QueryKeys.Credentials]: { component: CredentialDrawer },
      [QueryKeys.CardFormats]: { component: CardFormatDrawer },
      [QueryKeys.AccessLevels]: { component: AccessLevelDrawer },
      [QueryKeys.AccessLevelSchedules]: {
        component: AccessLevelScheduleDrawer,
      },
      [QueryKeys.Doors]: { component: DoorDrawer },
      [QueryKeys.Elevators]: {
        component: () => null,
        drawer: ElevatorFormDrawer,
      },
      [QueryKeys.ElevatorAccessLevels]: {
        component: ElevatorAccessLevelDrawer,
        header: ElevatorAccessLevelDrawerHeader,
      },
      [QueryKeys.ExternalUsers]: { component: () => null },
      [QueryKeys.FeatureFlags]: { component: FeatureFlagDrawer },
      [QueryKeys.Schedules]: { component: ScheduleDrawer },
      [QueryKeys.ScheduleInterval]: { component: ScheduleIntervalDrawer },
      [QueryKeys.SiteImports]: { component: SiteImportDrawer },
      [QueryKeys.Panels]: { component: PanelDrawer },
      // 'drawer' prop: new implementation using @smartrent/forms, using <Drawer /> in <Form />
      [QueryKeys.Readers]: { component: () => null, drawer: ReaderFormDrawer },

      // 'component' prop: old way, 1 drawer per route. Keeps Warbler Readers separate, for now.
      // See src/context/DrawerManager.tsx:126 for prop usage
      [QueryKeys.ReadersWarbler]: { component: ReaderDrawer },

      [QueryKeys.Inputs]: { component: InputDrawer },
      [QueryKeys.HolidayScheduleGroups]: {
        component: HolidayScheduleGroupDrawer,
      },
      [QueryKeys.ScheduleHolidayGroups]: {
        component: ScheduleHolidayGroupsDrawer,
      },
      [QueryKeys.HolidaySchedules]: { component: HolidayScheduleDrawer },
      [QueryKeys.Outputs]: { component: OutputDrawer },
      [QueryKeys.MemberAccessLevels]: { component: MemberAccessLevelDrawer },
      [QueryKeys.MemberDoors]: { component: MemberDoorsDrawer },
      [QueryKeys.SipAccounts]: { component: SipAccountDrawer },
      [QueryKeys.CallRooms]: { component: () => null },
      [QueryKeys.Events]: { component: EventDrawer },
      [QueryKeys.Operators]: { component: OperatorDrawer },
      [QueryKeys.SystemHealth]: { component: () => null },
      [QueryKeys.Floors]: { component: () => null, drawer: FloorFormDrawer },
      [QueryKeys.Encoders]: { component: () => null },
      [QueryKeys.EncodeCredential]: { component: CredentialEncodeDrawer },
      [QueryKeys.SiteFirmware]: { component: () => null },
      [QueryKeys.CascadingOptions]: { component: () => null },
      [QueryKeys.UserActivityLogs]: {
        component: () => null,
        drawer: UserActivityLogDrawer,
      },
    },
  }
);

export { AppDrawer, useAppDrawer };
