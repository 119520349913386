import { useCallback } from "react";

import {
  ListQueryResponse,
  Table,
  Typography,
  useTableQuery,
} from "@smartrent/ui";

import { QueryFunction } from "@tanstack/react-query";

import { QueryKeys } from "@/types";
import { useAppDrawer } from "@/components/layout/AppDrawer";
import { Schedule } from "@/modules/schedule/types";
import { Site } from "@/modules/site/types";

import { usePermissions } from "@/context/PolicyContext";

import { TableFilters } from "@/modules/base/table/types";

import { HolidayScheduleGroup } from "../holiday-groups/types";

import { CreateButton } from "../base/table/utils";

import { ScheduleHolidayGroupQueries } from "./queries";

interface ScheduleHolidayGroupsTableProps {
  filters: Partial<TableFilters & HolidayScheduleGroup>;
  schedule: Schedule;
  site: Site;
}

export const ScheduleHolidayGroupsTable = ({
  filters,
  site,
  schedule,
}: ScheduleHolidayGroupsTableProps) => {
  const drawer = useAppDrawer();

  const { canCreate } = usePermissions(ScheduleHolidayGroupQueries);

  const createAction = useCallback(() => {
    const params = { site_id: site.id, schedule_id: schedule.id };
    return drawer.push(QueryKeys.ScheduleHolidayGroups, {
      params,
    });
  }, [drawer, site, schedule]);

  const tableProps = useTableQuery<
    any,
    HolidayScheduleGroup,
    ListQueryResponse<HolidayScheduleGroup>
  >({
    fetch: ScheduleHolidayGroupQueries.fetch as unknown as QueryFunction<
      ListQueryResponse<HolidayScheduleGroup>
    >,
    getQueryKey: ({
      filters: tableFilters,
      page,
      pageSize,
      sortColumn,
      sortDirection,
    }) => [
      QueryKeys.HolidayScheduleGroups,
      {},
      {
        site_id: site.id,
        schedule_id: schedule.id,
        ...filters,
        page,
        per_page: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        ...tableFilters,
      },
    ],
    columns: [
      {
        name: "name",
        header: "Group Name",
        render: ({ row }) => {
          return <Typography>{row.name}</Typography>;
        },
      },
    ],
    defaultPageSize: 10,
  });
  return (
    <Table<HolidayScheduleGroup>
      title={"Exceptions"}
      noRecordsText="No Exceptions Groups Found For This Schedule"
      action={
        <CreateButton
          canCreate={canCreate}
          onCreate={createAction}
          text={"Modify Exceptions"}
        />
      }
      {...tableProps}
    />
  );
};
