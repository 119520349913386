import {
  VStack,
  FormikSelectField,
  FormikNumberInputField,
} from "@smartrent/ui";

import { Form } from "formik";
import * as yup from "yup";

import { BaseForm } from "@/modules/base/Form";

import { ReaderQueries } from "../queries";
import {
  LEDColorOptions,
  Reader,
  SubmitKeyOptions,
  ValidLedColors,
  ValidSubmitKeys,
} from "../types";

import { AddressOptions } from "../Drawer";

interface FormWithAddressesProps {
  reader: Reader;
  addressOptions: AddressOptions[];
  afterSubmit?: () => void;
}

const validationSchema = yup.object().shape({
  address: yup.number().required(),
  led_color: yup.string().required().oneOf(ValidLedColors),
  submit_key: yup.string().required().oneOf(ValidSubmitKeys),
  pin_digits: yup.number().required().min(4).max(8),
});

export const FormWithAddresses: React.FC<
  React.PropsWithChildren<FormWithAddressesProps>
> = ({ reader, addressOptions, afterSubmit }) => {
  return (
    <BaseForm<Reader>
      initialValues={reader}
      validationSchema={validationSchema}
      QueryClient={ReaderQueries}
      afterSubmit={afterSubmit}
    >
      {() => (
        <Form>
          <VStack spacing={16}>
            <FormikSelectField
              name={"address"}
              label={"Address"}
              options={addressOptions}
            />
            <FormikSelectField
              name={"led_color"}
              label={"LED Color"}
              options={LEDColorOptions}
            />
            <FormikSelectField
              name={"submit_key"}
              label={"Submit Key"}
              options={SubmitKeyOptions}
            />
            <FormikNumberInputField name={"pin_digits"} label={"Pin Digits"} />
          </VStack>
        </Form>
      )}
    </BaseForm>
  );
};
