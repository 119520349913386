import { View, StyleSheet } from "react-native";
import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { useCallback } from "react";
import {
  Button,
  DrawerActions,
  DrawerContent,
  FormikSubmit,
} from "@smartrent/ui";

import { MemberQueries } from "@/modules/member/queries";
import { useAppDrawer } from "@/components/layout/AppDrawer";
import { Door, MemberDoors } from "@/modules/door/types";
import { DoorMultiSelectField } from "@/modules/door/components/DoorSelectField";

import { Member } from "../types";

const validationSchema = yup.object().shape({
  member_id: yup.number().required(),
  door_ids: yup.array().of(yup.string()).required(),
});

interface MemberDoorFormProps {
  initialValues?: Member;
  params: {
    site_id: number;
  };
}

export const MemberDoorsForm = ({
  initialValues,
  params,
}: MemberDoorFormProps) => {
  const [assignDoor] = MemberQueries.useAssignDoorsMutation();
  const drawer = useAppDrawer();

  const handleBack = useCallback(() => {
    drawer.pop();
  }, [drawer]);

  const handleSubmit = useCallback(
    async (values: MemberDoors, formikHelpers: FormikHelpers<MemberDoors>) => {
      assignDoor(values);
      handleBack();
    },
    [assignDoor, handleBack]
  );

  return (
    <View style={styles.root}>
      <Formik<MemberDoors>
        initialValues={{
          member_id: initialValues?.id,
          door_ids: initialValues?.doors
            ? initialValues.doors.map((door: Door) => `${door.id}`)
            : [],
        }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {() => (
          <>
            <DrawerContent>
              <Form>
                <DoorMultiSelectField
                  name={"door_ids"}
                  label={"Doors"}
                  site_id={params.site_id}
                />
              </Form>
            </DrawerContent>
            <DrawerActions>
              <Button onPress={handleBack} variation={"outlined"}>
                Cancel
              </Button>
              <FormikSubmit
                label="Save"
                submittingLabel="Saving..."
                size="small"
              />
            </DrawerActions>
          </>
        )}
      </Formik>
    </View>
  );
};

// TODO: Make this not suck
const styles = StyleSheet.create({
  root: { flexGrow: 1 },
});
